import { Button, Modal, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';

type Props = {
  isError?: boolean;
  error?: AxiosError | null;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
};

const ErrorModal = ({
  isError,
  error,
  onCancel,
  onConfirm,
  confirmButtonLabel = 'Spróbuj ponownie',
  cancelButtonLabel = 'Zatwierdź',
}: Props) => {
  return (
    <Modal visible className="!space-y-6">
      <Text size="xl" className="text-center">
        Wystąpił błąd!
      </Text>
      {isError && <ErrorMessages error={error} />}

      <div className="flex justify-center gap-8">
        <Button onPress={onCancel}>{cancelButtonLabel}</Button>
        {onConfirm && (
          <Button variant="outline-primary" onPress={onConfirm}>
            {confirmButtonLabel}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ErrorModal;

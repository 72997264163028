import { Table } from '@profitowi/component-library';
import { useMemo } from 'react';

import useDictionaryContext from 'hooks/useDictionaryContext';
import { DocumentListItemMember } from 'types/documents';

import { createColumn } from './columns';

type Props = {
  members: DocumentListItemMember[];
};

const DocumentRecipientsDetails = ({ members }: Props) => {
  const { activeDictionaries } = useDictionaryContext();

  const columns = useMemo(
    () =>
      createColumn({
        dictionaries: activeDictionaries,
      }),
    [activeDictionaries]
  );

  return (
    <>
      <Table columns={columns} data={members} />
    </>
  );
};

export default DocumentRecipientsDetails;

import { Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Formik } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { generateReport } from 'services/reports';
import { GenerateReportPayload, ReportType, Reports } from 'types/reports';

import DocumentFiltersForm from '../DocumentFiltersForm/DocumentFiltersForm';
import { documentFiltersFormInitialValues } from '../DocumentFiltersForm/initialValues';
import {
  DocumentFiltersFormValues,
  documentFiltersFormValidationSchema,
} from '../DocumentFiltersForm/validationSchema';

type Payload = {
  reportType?: ReportType;
  payload: GenerateReportPayload[];
};

const GenerateReportDocumentsModal = () => {
  const navigate = useNavigate();

  const { error, isError, mutate, isLoading } = useMutation<
    AxiosResponse<Reports>,
    AxiosError,
    Payload
  >(({ reportType, payload }) => generateReport(payload, reportType), {
    onSettled: () => {
      navigate('/reports');
    },
  });

  function onSubmit(values: DocumentFiltersFormValues) {
    const payload: GenerateReportPayload[] = [
      { paramKey: 'AGENT_CODE', value: values?.agentCode },
      { paramKey: 'RECIPIENT_NAME', value: values?.recipientName },
      { paramKey: 'DOCUMENT_NAME', value: values?.documentName },
      { paramKey: 'TEMPLATE_ID', value: values?.templateId },
      { paramKey: 'DOCUMENT_TYPE', value: values?.documentTypeFromDict },
      {
        paramKey: 'DATE_FROM',
        value: !!values?.dateFrom && format(new Date(values?.dateFrom), 'dd-MM-yyyy'),
      },
      {
        paramKey: 'DATE_TO',
        value: !!values?.dateTo && format(new Date(values?.dateTo), 'dd-MM-yyyy'),
      },
      { paramKey: 'DOCUMENT_STATUS', value: values?.documentStatus },
      { paramKey: 'MY_SIGNING_STATUS', value: values?.mySigningStatus },
      { paramKey: 'CREATED_BY', value: values?.createdBy },
    ];

    mutate({ payload, reportType: ReportType.DOCUMENTS });
  }

  return (
    <Modal title="Generowanie raportu" visible className="text-center !space-y-8">
      <Formik
        initialValues={documentFiltersFormInitialValues}
        validationSchema={documentFiltersFormValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}>
        {({}) => (
          <div className="text-left">
            <DocumentFiltersForm
              customActionButtons={
                <ModalActions
                  confirmLabel="Wygeneruj raport"
                  isLoading={isLoading}
                  onCancel={() => navigate('..')}
                />
              }
            />
          </div>
        )}
      </Formik>

      {isError && <ErrorMessages error={error} />}
    </Modal>
  );
};

export default GenerateReportDocumentsModal;

import React, { useEffect } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import NavigationToolbar from '../../components/NavigationToolbar/NavigationToolbar';
import CreateTemplateDocumentFormWrapper from './CreateDocumentFromTemplate/CreateTemplateDocumentFormWrapper';
import CreatePdfDocumentFormWrapper from './CreateDocumentsFromPdf/CreatePdfDocumentFormWrapper';
import DocumentsList from './DocumentsList/DocumentsList';

function Documents() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/documents') navigate('active');
  }, [location.pathname]);

  return (
    <div className="flex-1 p-8 space-y-6 overflow-hidden">
      <>
        <NavigationToolbar
          rootPath="/documents/*"
          tabs={[
            { displayName: 'Aktualne', route: 'active' },
            { displayName: 'Archiwalne', route: 'archived' },
          ]}
        />
      </>

      <Routes>
        <Route path="active/*" element={<DocumentsList isActiveDocumentsView={true} />}>
          <Route
            path="create-document-from-template/*"
            element={<CreateTemplateDocumentFormWrapper />}
          />
          <Route path="create-document-from-pdf/*" element={<CreatePdfDocumentFormWrapper />} />
        </Route>
        <Route path="archived/*" element={<DocumentsList isActiveDocumentsView={false} />} />
      </Routes>

      <Outlet />
    </div>
  );
}

export default Documents;

import { S3File } from './templateCreator';

export enum DocumentPaperType {
  CORPORATE = 'Papier firmowy',
  USUAL = 'Brak papieru',
}

export enum RecipientType {
  SINGLE_RECIPIENT = 'Pojedynczy użytkownik',
  MULTIPLE_RECIPIENTS = 'Grupa użytkowników - filtry sieci struktury sprzedaży',
  CSV_FILE = 'Plik .csv',
}

export enum DocumentSignatureStatus {
  SIGNED_DOCUMENT = 'Dokument z podpisem',
  UNSIGNED_DOCUMENT = 'Dokument bez podpisu',
}

export enum ProxyRoles {
  AGENT = 'Pełnomocnik',
  GOVERNOR = 'Członek zarządu',
  BOARD_PRESIDENT = 'Prezes zarządu',
  EMPTY = 'Brak',
}

export enum DocumentMemberRoles {
  RECEIVER = 'Odbiorca',
  PARTY = 'Strona',
}

export enum DocumentGenerationType {
  PDF_BASED = 'PDF_BASED',
  TEMPLATE_BASED = 'TEMPLATE_BASED',
}

export enum UserDocumentRole {
  RECEIVER = 'RECEIVER',
  PARTY = 'PARTY',
  AGENT = 'AGENT',
  CONFIDENTIAL_CLERK = 'CONFIDENTIAL_CLERK',
  GOVERNOR = 'GOVERNOR',
  BOARD_PRESIDENT = 'BOARD_PRESIDENT',
  EMPTY = 'EMPTY',
}

export enum DocumentStatus {
  DRAFT = 'DRAFT',
  IN_SENDING = 'IN_SENDING',
  SENT_COMPLETED = 'SENT_COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum SigningStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  OPENED = 'OPENED',
  SIGNED = 'SIGNED',
  READ = 'READ',
  RECEIVED = 'RECEIVED',
}

export interface RecipientsCsvResponse {
  receiverId: string;
  parties: {
    order: number;
    externalId: string;
    documentRole: UserDocumentRole.PARTY;
  }[];
}

export interface DocumentTemplateAssignmentMember {
  externalId: string;
  documentRole: UserDocumentRole;
  signatureOrder: number | null;
  order?: number;
}

export interface DocumentTemplateAssignmentReceiver {
  receiverId: string;
  members: DocumentTemplateAssignmentMember[];
}

export type DocumentTemplateMember = Omit<
  DocumentTemplateAssignmentMember,
  'signatureOrder' | 'order'
>[];

export interface CreateDocumentRequestParams {
  shouldSend: boolean;
  documentName: string;
  customContent: string;
  isSignatureRequired: boolean;
  documentGenerationType: DocumentGenerationType;
  date: string;
  assignments: DocumentTemplateAssignmentReceiver[];
  attachmentsFromTemplates: string[];
  baseTemplateId?: number;
  members: DocumentTemplateMember;
  documentType?: string;
  paperType?: DocumentPaperType;
}

export interface DraftDocumentUpdateRequest {
  id: number;
  content: string;
}

export interface GetDocumentsRequest {
  isArchived: boolean;
  agentCode?: string;
  recipientName?: string;
  documentName?: string;
  templateId?: number;
  documentTypeFromDict?: string;
  documentStatus?: DocumentStatus;
  mySigningStatus?: SigningStatus;
  canViewAllDocuments?: boolean;
  createdBy?: string;
  dateFrom?: Date | string;
  dateTo?: Date | string;
}

export interface DocumentListItemMember {
  fullName: string;
  agentCode: string;
  role: UserDocumentRole;
  userStatus: SigningStatus;
  signatureDate: Date;
}

export interface DocumentListItem {
  id: number;
  documentStatus: DocumentStatus;
  currentLoggedUserStatus: SigningStatus;
  documentName: string;
  documentTypeFromDictionary: string;
  createdAt: Date;
  createdBy: string;
  comment: string;
  documentContent: string;
  isArchived: boolean;
  members: DocumentListItemMember[];
}

export interface SigningOrder {
  id: number;
  documentId: number;
  documentRole: string;
  signatureOrder: number;
  agentId: string;
}

export interface DocumentRecipient {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  agentId: string;
  sourceSystem: string;
  externalId: string;
  signingTasks: string[];
  agentCode: string;
  pesel: number;
  regon: number;
  nip: number;
  address: string;
  companyAddress: string;
  postAddress: string;
  office: string;
  companyName: string;
  companyType: string;
  accountNumber: number;
  rau: string;
  username: string;
}

export interface GetDocumentTemplateResponse {
  id: number;
  name: string;
  createdAt: Date;
  documentGenerationType: DocumentGenerationType;
  documentType: string;
  customContent: string;
  paperType: DocumentPaperType;
  status: DocumentStatus;
  agents: DocumentTemplateMember;
  parties: DocumentTemplateMember;
  signingOrders: SigningOrder[];
  recipient: DocumentRecipient;
  attachments: S3File[];
  baseTemplateId: number;
  isSignatureRequired: boolean;
  agentsProperties: string[];
}

export interface DocumentHistory {
  id: number;
  action: string;
  status: DocumentStatus;
  sender: string;
  receiver: string | null;
  actionTime: string;
  documentName: string;
}

export interface SignDocumentResponse {
  blockedUntil: string;
  dailyCodeNumber: number;
  validUntil: string;
}

export interface SignDocumentRequest {
  otpCode: string;
  documentIds: number[];
  ipAddress: string;
  browserMetadata: string;
}

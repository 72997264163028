import { useNavigate } from 'react-router-dom';

import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import AuthService from '../../services/auth';

const Logout = () => {
  const navigate = useNavigate();

  return (
    <ConfirmationModal
      confirmMessage="Czy na pewno chcesz wylogować użytkownika?"
      mutate={AuthService.doLogout}
      onCancel={() => navigate('..')}
    />
  );
};

export default Logout;

import AdvanceInstallments from 'pages/AdvanceInstallments/AdvanceInstallments';
import AgentBonusPayments from 'pages/AgentBonusPayments/AgentBonusPayments';
import DebtInstallments from 'pages/DebtInstallments/DebtInstallments';
import Forbidden from 'pages/Forbidden/Forbidden';
import MyAccount from 'pages/MyAccount/MyAccount';
import Reports from 'pages/Reports/Reports';
import SettlementEmails from 'pages/SettlementEmails/SettlementEmails';
import SettlementPreview from 'pages/Settlements/SettlementPreview/SettlementPreview';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Navbar } from 'components';
import ErrorBoundary from 'components/ErrorBoundary';
import { AgentRoleContextProvider } from 'hooks/useAgentRoleContext';
import { DictionaryContextProvider } from 'hooks/useDictionaryContext';
import { DocumentContextProvider } from 'hooks/useDocumentContext';

import Agent from './pages/Agent/Agent';
import Commissions from './pages/Commissions/Commissions';
import Communication from './pages/Communication/Communication';
import ConfigurationLevels from './pages/ConfigurationLevels/ConfigurationLevels';
import Dashboard from './pages/Dashboard/Dashboard';
import Documents from './pages/Documents/Documents';
import Logout from './pages/Logout/Logout';
import Records from './pages/Records/Records';
import Settings from './pages/Settings/Settings';
import Settlements from './pages/Settlements/Settlements';

const App = () => {
  return (
    <BrowserRouter>
      <DictionaryContextProvider>
        <AgentRoleContextProvider>
          <DocumentContextProvider>
            <div className="flex">
              <Navbar />
              <ErrorBoundary>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/commissions/*" element={<Commissions />} />
                  <Route
                    path="commissions/commission-schemas/:commissionSchemaId"
                    element={<ConfigurationLevels />}
                  />
                  <Route path="/records/*" element={<Records />} />
                  <Route path="/records/agent/:id/*" element={<Agent />} />
                  <Route
                    path="/records/agent/:agentId/advances/:advanceId"
                    element={<AdvanceInstallments />}
                  />
                  <Route
                    path="records/agent/:agentId/bonuses/:bonusId"
                    element={<AgentBonusPayments />}
                  />
                  <Route
                    path="/records/agent/:agentId/debt/:debtRepaymentId"
                    element={<DebtInstallments />}
                  />
                  <Route path="/communication/*" element={<Communication />} />

                  <Route path="/settlements/*" element={<Settlements />} />
                  <Route path="settlements/:settlementId/emails" element={<SettlementEmails />} />
                  <Route
                    path="settlements/:settlementId/preview/*"
                    element={<SettlementPreview />}
                  />
                  <Route path="/documents/*" element={<Documents />} />
                  <Route path="/reports/*" element={<Reports />} />
                  <Route path="/ustawienia/*" element={<Settings />} />
                  <Route path="/konto" element={<MyAccount />} />
                  <Route path="/wyloguj" element={<Logout />} />
                  <Route path="/403" element={<Forbidden />} />
                </Routes>
              </ErrorBoundary>
            </div>
          </DocumentContextProvider>
        </AgentRoleContextProvider>
      </DictionaryContextProvider>
    </BrowserRouter>
  );
};

export default App;

import { Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format, startOfDay } from 'date-fns';
import { Formik } from 'formik';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { generateReport } from 'services/reports';
import { GenerateReportPayload, ReportType, Reports } from 'types/reports';

import DownloadReportModal from '../DownloadReportModal/DownloadReportModal';
import { generateReportInitialValue } from '../helper/form';
import useReportConfigQuery from '../hooks/useReportConfigQuery';
import ReportForm from './ReportForm/ReportForm';
import { createValidationSchema } from './ReportForm/validation';

type ParamValue = string | boolean | string[];

type Payload = {
  reportType?: ReportType;
  payload: GenerateReportPayload[];
};

type Props = {
  refetchList?: () => void;
  initialReportType?: ReportType;
};

const ReportModal = ({ initialReportType, refetchList }: Props) => {
  const [reportType, setReportType] = useState(initialReportType);
  const [report, setReport] = useState<Reports>();
  const { state } = useLocation();

  const { reportConfig } = useReportConfigQuery({
    reportType,
  });

  const validation = useMemo(
    () => reportConfig && createValidationSchema(reportConfig[0]),
    [reportConfig]
  );

  const { error, isError, mutate, isLoading } = useMutation<
    AxiosResponse<Reports>,
    AxiosError,
    Payload
  >(({ reportType, payload }) => generateReport(payload, reportType), {
    onSuccess: (data) => {
      setReport(data.data);
    },
  });

  const initialValues = useMemo(
    () =>
      state?.defaultInitialValues
        ? state.defaultInitialValues
        : generateReportInitialValue(reportType, reportConfig),
    [reportType, reportConfig, state]
  );

  function generateDocumentReport(values: Record<string, ParamValue>) {
    const payload: GenerateReportPayload[] = [
      { paramKey: 'AGENT_CODE', value: values.agentCode as string },
      { paramKey: 'RECIPIENT_NAME', value: values.recipientName as string },
      { paramKey: 'DOCUMENT_NAME', value: values.documentName as string },
      { paramKey: 'TEMPLATE_ID', value: values.templateId as string },
      { paramKey: 'DOCUMENT_TYPE', value: values.documentTypeFromDict as string },
      {
        paramKey: 'DATE_FROM',
        value: values.dateFrom && format(new Date(values.dateFrom as string), 'dd-MM-yyyy'),
      },
      {
        paramKey: 'DATE_TO',
        value: values.dateTo && format(new Date(values.dateTo as string), 'dd-MM-yyyy'),
      },
      { paramKey: 'DOCUMENT_STATUS', value: values.documentStatus as string },
      { paramKey: 'MY_SIGNING_STATUS', value: values.mySigningStatus as string },
      { paramKey: 'CREATED_BY', value: values.createdBy as string },
    ];

    mutate({ payload, reportType });
  }

  const handleSubmit = (values: Record<string, ParamValue>) => {
    if (reportType === ReportType.DOCUMENTS) {
      return generateDocumentReport(values);
    }

    const paramPayload: GenerateReportPayload[] = [];

    if (values) {
      Object.keys(values).map((param) => {
        if (Array.isArray(values[param])) {
          const paramArray = values[param] as string[];

          if (paramArray.length) {
            paramArray.map((item) => paramPayload.push({ paramKey: param, value: item }));
          } else paramPayload.push({ paramKey: param, value: '' });
        } else {
          if (param === 'TIME' || param.includes('DATE')) {
            paramPayload.push({
              paramKey: param,
              value: format(startOfDay(new Date(values[param] as string)), 'dd-MM-yyyy HH:mm:ss'),
            });
          } else paramPayload.push({ paramKey: param, value: values[param].toString() as string });
        }
      });

      mutate({ payload: paramPayload, reportType });
    }
  };

  return (
    <Modal title="Wygeneruj raport" visible>
      <>
        {!report && initialValues && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validation}
            enableReinitialize>
            <>
              <ReportForm
                reportType={reportType}
                setReportType={setReportType}
                isSelectReportTypeDisabled={!!initialReportType}
                isLoading={isLoading}
              />
              {isError && <ErrorMessages error={error} />}
            </>
          </Formik>
        )}

        {report && (
          <DownloadReportModal refetchList={refetchList} report={report} setReport={setReport} />
        )}
      </>
    </Modal>
  );
};

export default ReportModal;

import qs from 'qs';

import {
  TemplateCreatorData,
  TemplateCreatorDataContent,
  TemplateFilterRequestParams,
} from '../types/templateCreator';
import { aperolApi } from '../utils/axios';

export const getTemplateCreatorData = async (
  payload: Partial<TemplateFilterRequestParams>,
  page: number,
  size: number,
  sort?: string
) => {
  const { data } = await aperolApi.get<TemplateCreatorData>(`/templates`, {
    params: { ...payload, page, size, sort },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return data;
};

export const getCreatorTemplate = async (id: number) => {
  const { data } = await aperolApi.get<TemplateCreatorDataContent>(`/templates/${id}`);

  return data;
};

export const archiveTemplates = async (ids: number[]) => {
  return await aperolApi.put<void>(`/templates/archive`, ids);
};

export const restoreTemplates = async (ids: number[]) => {
  return await aperolApi.put<void>(`/templates/restore`, ids);
};

export const createTemplate = async (formData: FormData) => {
  return await aperolApi.post<void>(`/templates`, formData);
};

export const editTemplate = async (id: number, data: FormData) => {
  return await aperolApi.put<void>(`/templates/${id}`, data);
};

export const deleteTemplates = async (ids: number[]) => {
  return await aperolApi.delete<void>(`/templates`, { data: ids });
};

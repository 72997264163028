import { Modal, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import ModalActions from 'components/ModalActions';

type Props = {
  modalBody?: ReactNode;
  confirmMessage?: string;
  isLoading?: boolean;
  isError?: boolean;
  error?: AxiosError | null;
  disabledSubmit?: boolean;
  mutate: () => void;
  onCancel?: () => void;
  title?: string;
};

const ConfirmationModal = ({
  modalBody,
  confirmMessage,
  isLoading,
  isError,
  error,
  disabledSubmit,
  mutate,
  onCancel,
  title,
}: Props) => {
  const navigate = useNavigate();

  return (
    <Modal title={title} visible className="!space-y-6">
      {modalBody}
      {confirmMessage && <Text className="text-center">{confirmMessage}</Text>}
      <ModalActions
        confirmLabel="Zatwierdź"
        isLoading={isLoading}
        disableSubmit={disabledSubmit}
        onCancel={() => (typeof onCancel === 'function' ? onCancel() : navigate('..'))}
        onConfirm={() => mutate()}
      />
      {isError && <ErrorMessages error={error} />}
    </Modal>
  );
};

export default ConfirmationModal;

import { Box, Button, Header, Loader, Modal, Table, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import CommissionOtherImportForm from 'pages/Commissions/components/CommissionOtherImports/CommissionOtherImportForm/CommissionOtherImportForm';
import { columns } from 'pages/Commissions/components/CommissionOtherImports/columns';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { FILE } from 'constants/queries/file';
import { usePagination } from 'hooks/usePagination';
import { deleteFile, getFileList } from 'services/file';
import { FileFromBackend, FileTypes } from 'types/file';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import BonusesFileForm from './BonusesFileForm/BonusesFileForm';

const CommissionOtherImports = () => {
  const [visible, setVisible] = useState(false);
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const [confirmModaVisible, setConfirmModalVisible] = useState(false);
  const [fileId, setFileId] = useState<number>();

  const pagination = usePagination(10);
  const navigate = useNavigate();
  const { perPage, currentPage } = pagination;

  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const {
    data: fileList,
    refetch,
    isFetching,
    isSuccess,
    isError,
    isLoading,
    error,
  } = useQuery<Page<FileFromBackend>, AxiosError>(
    [FILE.DOWNLOAD, FileTypes.CLIENT_DATA, currentPage, perPage, sortBy],
    () =>
      getFileList(
        [FileTypes.CLIENT_DATA, FileTypes.DISCRETIONARY_BONUSES],
        currentPage,
        perPage,
        false,
        decodeUriSortParams(sortBy)
      ),
    { keepPreviousData: true }
  );

  const {
    mutate: mutateDeleteFile,
    isError: isDeleteFileError,
    error: deleteFileError,
    isLoading: isDeleteFileLoading,
  } = useMutation<AxiosResponse, AxiosError>(() => deleteFile(fileId), {
    onSuccess: () => {
      refetch();
      closeConfirmModal();
    },
  });

  const onDeleteFile = (fileId: number) => {
    setFileId(fileId);
    setConfirmModalVisible(true);
  };

  const closeConfirmModal = () => {
    setFileId(undefined);
    setConfirmModalVisible(false);
  };

  return (
    <>
      <Box className="space-y-6 max-h-xl">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Inne źródła danych
          </Header>
          <div className="flex space-x-4 items-center">
            <Button variant="outline-primary" onPress={() => setVisible(true)}>
              <Text weight="semibold">
                Dodaj dane klienta
                <i className="bi bi-plus-lg ml-2" />
              </Text>
            </Button>

            <Button
              variant="outline-primary"
              className="px-4 py-1"
              onPress={() => navigate('addBonuses')}>
              <Text weight="semibold">
                Dodaj plik z premiami
                <i className="bi bi-plus-lg ml-2"></i>
              </Text>
            </Button>
          </div>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && fileList && (
          <Box.FullWidth>
            {/* TODO - Table sometimes display items in wrong order, need furter research */}
            <Table
              totalPages={fileList.totalPages}
              pagination={pagination}
              data={fileList.content}
              isLoading={isFetching}
              columns={columns(onDeleteFile)}
              onSortBy={handleSortBy}
              sortBy={sortBy}
            />
          </Box.FullWidth>
        )}
      </Box>

      {visible && (
        <Modal title="Dodaj inne źródło danych" visible={visible} setVisible={setVisible}>
          <CommissionOtherImportForm visible={visible} setVisible={setVisible} refetch={refetch} />
        </Modal>
      )}

      {confirmModaVisible && (
        <ConfirmationModal
          confirmMessage="Czy na pewno chcesz usunąć plik?"
          isError={isDeleteFileError}
          isLoading={isDeleteFileLoading}
          error={deleteFileError}
          mutate={mutateDeleteFile}
          onCancel={closeConfirmModal}
        />
      )}

      <Routes>
        <Route path="addBonuses" element={<BonusesFileForm refetch={refetch} />}></Route>
      </Routes>
    </>
  );
};
export default CommissionOtherImports;

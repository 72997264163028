import { Box, Loader } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import InsetLoader from 'components/InsetLoader/InsetLoader';
import { assignFormValuesToFormData } from 'utils/assignFormValuesToFormData';

import {
  createTemplate,
  editTemplate,
  getCreatorTemplate,
} from '../../../../../services/templateCreator';
import { TemplateCreatorDataContent } from '../../../../../types/templateCreator';
import { TemplateCreatorTabs } from '../TemplateCreator';
import { TemplateCreatorActions } from '../TemplateCreatorList/columns';
import EditTemplateForm, { TemplateFormValues } from './EditTemplateForm/EditTemplateForm';

interface Params {
  setCurrentTab: (tab: TemplateCreatorTabs) => void;
  templateFormAction: TemplateCreatorActions;
  editId?: number;
  setTemplateFormAction: (action: TemplateCreatorActions | undefined) => void;
  setEditId: (action: number | undefined) => void;
}

function EditTemplate({
  setCurrentTab,
  templateFormAction,
  editId,
  setTemplateFormAction,
  setEditId,
}: Params) {
  const {
    data,
    isError,
    error,
    refetch,
    isLoading: isLoadingData,
    isFetching: isFetchingData,
  } = useQuery<TemplateCreatorDataContent, AxiosError>(
    ['getCreatorTemplate', editId],
    () => getCreatorTemplate(editId as number),
    {
      enabled:
        Number.isInteger(editId) &&
        (templateFormAction === TemplateCreatorActions.COPY ||
          templateFormAction === TemplateCreatorActions.EDIT),
    }
  );

  const {
    error: errorCreate,
    isError: isErrorCreate,
    mutate: mutateCreate,
    reset: resetCreate,
    isLoading: isFetchingCreateTemplate,
  } = useMutation<AxiosResponse, AxiosError, FormData>((data: FormData) => createTemplate(data), {
    onSuccess: () => {
      setCurrentTab(TemplateCreatorTabs.FORM_VIEW);
      setTemplateFormAction(undefined);
      setEditId(undefined);
    },
  });

  const {
    error: errorEdit,
    isError: isErrorEdit,
    mutate: mutateEdit,
    reset: resetEdit,
    isLoading: isLoadingEditTemplate,
  } = useMutation<AxiosResponse, AxiosError, { id: number; data: FormData }>(
    ({ id, data }) => editTemplate(id, data),
    {
      onSuccess: () => {
        setCurrentTab(TemplateCreatorTabs.FORM_VIEW);
        setTemplateFormAction(undefined);
        setEditId(undefined);
      },
    }
  );

  function handleSubmit(values: TemplateFormValues) {
    const formData = new FormData();
    const alreadyUploadedAttachments = editedData?.attachments?.filter((attachment) => {
      return values.attachments?.find((value) => value?.uploadedFileId === attachment.id);
    });

    let template: Partial<TemplateCreatorDataContent> = {
      name: values.name?.trim(),
      documentType: values.documentType,
      description: values.description?.trim(),
      content: values.content,
      attachments: !!alreadyUploadedAttachments ? alreadyUploadedAttachments : [],
    };

    if (values.attachments?.length) {
      values.attachments.forEach((value): FormData | undefined => {
        if (!value) return;
        if (
          !alreadyUploadedAttachments?.find((attachment) => attachment.id === value.uploadedFileId)
        ) {
          formData.append('attachments', value.file);
          return formData;
        }
      });
    }

    assignFormValuesToFormData<Partial<TemplateCreatorDataContent>>(formData, template, 'template');

    if (
      templateFormAction === TemplateCreatorActions.CREATE ||
      templateFormAction === TemplateCreatorActions.COPY
    ) {
      mutateCreate(formData);
    }
    if (templateFormAction === TemplateCreatorActions.EDIT && data && !Number.isNaN(data)) {
      mutateEdit({ id: data.id as number, data: formData });
    }
  }

  function handleCancel() {
    setCurrentTab(TemplateCreatorTabs.LIST_VIEW);
  }

  function resetErrors() {
    if (error) refetch();
    if (errorEdit) resetEdit();
    if (errorCreate) resetCreate();
  }

  const editedData: TemplateCreatorDataContent | undefined = useMemo(() => {
    if (!editId) return undefined;

    if (templateFormAction === TemplateCreatorActions.COPY) {
      return {
        content: data?.content,
        documentType: data?.documentType,
      } as TemplateCreatorDataContent;
    }
    return data;
  }, [data, templateFormAction, editId]);

  return (
    <div className="w-full h-full overflow-hidden relative">
      <Box className="flex flex-col">
        {(isFetchingCreateTemplate || isLoadingEditTemplate) && <InsetLoader />}
        {isLoadingData || isFetchingData ? (
          <Loader className="my-12 h-12 w-12" />
        ) : (
          <EditTemplateForm
            isLoading={isFetchingCreateTemplate || isLoadingEditTemplate}
            editedItem={editedData}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            resetErrors={resetErrors}
            isError={isErrorCreate || isError || isErrorEdit}
            error={(error || errorCreate || errorEdit) as AxiosError}
          />
        )}
      </Box>
    </div>
  );
}

export default EditTemplate;

import {
  AutoCompleteField,
  Button,
  Header,
  Icon,
  Loader,
  SelectField,
  Text,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Field, Form, useFormikContext } from 'formik';
import {
  CreatePdfDocumentFormValues,
  CreatePdfDocumentFormValuesStep2,
} from 'pages/Documents/CreateDocumentsFromPdf/CreatePdfDocumentFormData/createPdfDocumentFormValidationSchema';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { STRUCTURE } from 'constants/queries/structure';
import { uploadDocumentRecipientsCsv } from 'services/documents';
import { getSubordinationStructureView } from 'services/structure';
import { Page } from 'types/request';
import { AgentRelationStructureRecord } from 'types/structure';

import ErrorMessages from '../../../../../../components/ErrorMessages/ErrorMessages';
import { DICTIONARY_TYPES } from '../../../../../../constants/dictionaryTypes';
import { COMMISSION } from '../../../../../../constants/queries/commission';
import { createAgentsOptions } from '../../../../../../helpers/agents';
import { createSchemaOptions } from '../../../../../../helpers/schemas';
import { useAgentQuery } from '../../../../../../hooks/useAgentQuery';
import useDictionaryContext from '../../../../../../hooks/useDictionaryContext';
import { getCommissionSchemas } from '../../../../../../services/commission';
import {
  DocumentTemplateAssignmentMember,
  DocumentTemplateAssignmentReceiver,
  RecipientType,
  RecipientsCsvResponse,
  UserDocumentRole,
} from '../../../../../../types/documents';

interface Props {
  onReset: () => void;
}

const RecipientsModal = ({ onReset }: Props) => {
  const [queryUser, setQueryUser] = useState<string>('');
  const [queryParty, setQueryParty] = useState<string>('');
  const [assignmentReceivers, setAssignmentReceivers] = useState<
    DocumentTemplateAssignmentReceiver[]
  >([]);
  const { activeDictionaries } = useDictionaryContext();
  const { values, errors, touched, setFieldValue, setTouched, setErrors, setFieldError } =
    useFormikContext<CreatePdfDocumentFormValuesStep2>();

  useEffect(() => {
    setTouched({});
    setErrors({});
  }, []);

  const {
    data: searchUser,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    error: errorUsers,
  } = useAgentQuery({
    queryParam: queryUser,
    minQueryLength: 3,
    perPage: 99999,
  });

  const {
    data: searchParty,
    isLoading: isLoadingParties,
    isError: isErrorParties,
    error: errorParties,
  } = useAgentQuery({
    queryParam: queryParty,
    minQueryLength: 3,
    perPage: 99999,
  });

  const { data: schemaList, isFetched: schemasIsFetched } = useQuery(
    [COMMISSION.COMMISSION_SCHEMAS, 0, 10000],
    () => getCommissionSchemas(0, 10000)
  );

  const {
    error: errorSubordinateStructure,
    isError: isErrorSubordinateStructure,
    isFetching: isFetchingSubordinateStructure,
    isLoading: isLoadingSubordinateStructure,
  } = useQuery<Page<AgentRelationStructureRecord>, AxiosError>(
    [STRUCTURE.STRUCTURE_SUBORDINATION, values.schema, values.positions],
    () =>
      getSubordinationStructureView(
        {
          commissionSchema: values.schema,
          childPositions: values.positions,
          time: format(new Date(), 'dd-MM-yyyy HH:mm:ss'),
        },
        0,
        9999
      ),
    {
      enabled:
        !!values.schema?.toString()?.length &&
        !!values.positions?.length &&
        values.recipientContactMethod === RecipientType.MULTIPLE_RECIPIENTS,
      onSuccess: (data) => {
        const assignmentList: DocumentTemplateAssignmentReceiver[] = [];

        data.content.forEach((agent) => {
          if (assignmentList.find((receiver) => receiver.receiverId === agent.child.id.toString()))
            return;

          assignmentList.push({
            receiverId: agent.child.id.toString(),
            members: [
              {
                documentRole: UserDocumentRole.RECEIVER,
                externalId: agent.child.id.toString(),
                signatureOrder: null,
              },
            ],
          });
        });

        setAssignmentReceivers(assignmentList);
      },
    }
  );

  const {
    mutate: uploadRecipientsCsvMutate,
    isLoading: isLoadingUploadRecipientsCsv,
    error: errorUploadRecipientsCsv,
    isError: isErrorUploadRecipientsCsv,
    reset: resetUploadRecipientsCsv,
  } = useMutation<AxiosResponse<RecipientsCsvResponse[]>, AxiosError, FormData>(
    (file) => uploadDocumentRecipientsCsv(file),
    {
      onSuccess: ({ data }) => {
        const assignmentList: DocumentTemplateAssignmentReceiver[] = data.map((receiver) => ({
          receiverId: receiver.receiverId,
          members: [
            ...receiver.parties.map((party) => ({
              documentRole: UserDocumentRole.PARTY,
              externalId: party.externalId,
              signatureOrder: null,
            })),
            {
              documentRole: UserDocumentRole.RECEIVER,
              externalId: receiver.receiverId,
              signatureOrder: null,
            },
          ],
        }));

        setAssignmentReceivers(assignmentList);
      },
      onError: (error) => {
        setFieldError('csvFile', 'Server returned error' + error.code);
      },
    }
  );

  const userOptions = useMemo(
    () =>
      createAgentsOptions(searchUser?.content || [], 'id').filter(
        (option) => option.key !== values.party
      ),
    [searchUser, values.party]
  );

  const partyOptions = useMemo(
    () =>
      createAgentsOptions(searchParty?.content || [], 'id').filter(
        (option) => option.key !== values.user
      ),
    [searchParty, values.user]
  );

  useEffect(() => {
    if (queryUser.length === 0) {
      setFieldValue('user', '');
    }
  }, [queryUser]);

  useEffect(() => {
    if (queryParty.length === 0) {
      setFieldValue('party', '');
    }
  }, [queryParty]);

  const recipientTypeOptions = Object.values(RecipientType).map((type) => ({
    value: type,
    key: type,
  }));

  function onFileInputChange(form: any, event: any) {
    const file = event.target.files[0];
    form.setFieldValue(`csvFile`, file);

    const formData = new FormData();
    formData.append('recipients', file);
    uploadRecipientsCsvMutate(formData);
  }

  useEffect(() => {
    const assignmentList: DocumentTemplateAssignmentReceiver[] = [];

    if (values?.user) {
      const membersList: DocumentTemplateAssignmentMember[] = [
        {
          documentRole: UserDocumentRole.RECEIVER,
          externalId: values.user,
          signatureOrder: null,
        },
      ];

      if (values.party) {
        membersList.push({
          documentRole: UserDocumentRole.PARTY,
          externalId: values.party,
          signatureOrder: null,
        });
      }

      assignmentList.push({
        receiverId: values.user,
        members: membersList,
      });
    }

    setAssignmentReceivers(assignmentList);
  }, [values.user, values.party]);

  useEffect(() => {
    setAssignmentReceivers([]);
    resetUploadRecipientsCsv();

    if (values.recipientContactMethod !== RecipientType.SINGLE_RECIPIENT) {
      setFieldValue('user', undefined);
      setFieldValue('party', undefined);
      setQueryUser('');
      setQueryParty('');
    }
    if (values.recipientContactMethod !== RecipientType.MULTIPLE_RECIPIENTS) {
      setFieldValue('schema', undefined);
      setFieldValue('positions', undefined);
    }
    if (values.recipientContactMethod !== RecipientType.CSV_FILE) {
      setFieldValue('file', undefined);
    }
  }, [values.recipientContactMethod]);

  function onSubmit() {
    const formValues = values as CreatePdfDocumentFormValues;
    const proxyMembers: DocumentTemplateAssignmentMember[] =
      formValues.proxies?.map((proxy) => ({
        externalId: proxy.id,
        documentRole: proxy.role,
        signatureOrder: null,
      })) || [];
    const assignmentsArray = [...formValues.assignments, ...assignmentReceivers];

    const assignments = assignmentsArray.map((assignment) => ({
      ...assignment,
      members: [...assignment.members, ...proxyMembers],
    }));

    setFieldValue('assignments', assignments);
  }

  return (
    <>
      <div className="flex flex-col items-center">
        <Header as="h1" size="xl" weight="semibold">
          Utwórz dokument pdf
        </Header>
        <Text size="lg">Krok 2 - strony i odbiorcy</Text>
      </div>

      <Text className="px-8 text-slate-500">
        Uwaga! Przy tworzeniu dokumentu dla odbiorców określanych za pomocą filtrów struktury
        sprzedaży nie ma możliwości wskazania strony dokumentu.
      </Text>

      <div>
        <Form>
          <div className="my-4 flex flex-col gap-2">
            <SelectField
              name="recipientContactMethod"
              label="Sposób wskazania odbiorcy"
              options={recipientTypeOptions}
            />

            {values.recipientContactMethod === RecipientType.SINGLE_RECIPIENT && (
              <>
                <AutoCompleteField
                  label="Użytkownik"
                  name="user"
                  options={userOptions}
                  placeholder="Użytkownik"
                  query={queryUser}
                  setQuery={setQueryUser}
                  className={`${touched.user && errors?.user ? 'border border-red-700' : ''}`}
                />
                <AutoCompleteField
                  label="Strona"
                  name="party"
                  options={partyOptions}
                  placeholder="Strona"
                  query={queryParty}
                  setQuery={setQueryParty}
                  className={`${touched.party && errors?.party ? 'border border-red-700' : ''}`}
                />
              </>
            )}
            {values.recipientContactMethod === RecipientType.MULTIPLE_RECIPIENTS && (
              <>
                <SelectField
                  name="schema"
                  placeholder="Schemat"
                  options={schemaList && schemasIsFetched ? createSchemaOptions(schemaList) : []}
                />
                <SelectField
                  name="positions"
                  placeholder="Stanowiska"
                  options={activeDictionaries?.[DICTIONARY_TYPES.POSITION] || []}
                />
              </>
            )}
            {values.recipientContactMethod === RecipientType.CSV_FILE && (
              <>
                <Field name="csvFile">
                  {({ form }: any) => (
                    <>
                      <input
                        type="file"
                        accept=".csv"
                        className="w-full"
                        name="csvFile"
                        onChange={(event) => onFileInputChange(form, event)}
                      />
                    </>
                  )}
                </Field>
                {errors.csvFile && touched.csvFile && (
                  <div>
                    <Text size="sm" className="text-red-700">
                      {errors.csvFile}
                    </Text>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="flex justify-end gap-4">
            <Button
              className="flex space-x-2"
              onPress={onReset}
              type="reset"
              variant="outline-primary">
              <Icon name="x-lg" />
              <Text>Anuluj</Text>
            </Button>
            <Button
              className="flex space-x-2"
              type="submit"
              onPress={onSubmit}
              isDisabled={
                isLoadingUsers ||
                isLoadingParties ||
                !!Object.entries(errors).length ||
                isFetchingSubordinateStructure ||
                isLoadingUploadRecipientsCsv ||
                isLoadingSubordinateStructure
              }
              variant="primary">
              {isLoadingUsers ||
              isLoadingParties ||
              isFetchingSubordinateStructure ||
              isLoadingUploadRecipientsCsv ||
              isLoadingSubordinateStructure ? (
                <Loader className="h-4 w-4 !border-white" />
              ) : (
                <>
                  <Icon name="arrow-right" />
                </>
              )}
              <Text>Dalej</Text>
            </Button>
          </div>
          {(isErrorUsers ||
            isErrorParties ||
            isErrorSubordinateStructure ||
            isErrorUploadRecipientsCsv) && (
            <ErrorMessages
              error={
                errorUsers || errorParties || errorSubordinateStructure || errorUploadRecipientsCsv
              }
            />
          )}
        </Form>
      </div>
    </>
  );
};

export default RecipientsModal;

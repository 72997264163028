export const assignFormValuesToFormData = <T>(
  formData: FormData,
  template: T,
  templateName: string
) => {
  const json = JSON.stringify(template);
  const blob = new Blob([json], {
    type: 'application/json',
  });
  formData.append(templateName, blob);
};

import {
  DocumentGenerationType,
  DocumentSignatureStatus,
  RecipientType,
} from '../../../../types/documents';
import { CreatePdfDocumentFormValues } from './createPdfDocumentFormValidationSchema';

export const createPdfDocumentFormInitialValues: CreatePdfDocumentFormValues = {
  documentName: '',
  documentBase: undefined as any,
  members: [],
  proxies: [],
  recipientContactMethod: RecipientType.SINGLE_RECIPIENT,
  user: '',
  party: '',
  schema: '',
  positions: '',
  csvFile: undefined as any,
  attachments: [],
  signature: DocumentSignatureStatus.SIGNED_DOCUMENT,
  queue: [],
  assignments: [],
  customContent: '',
  documentGenerationType: DocumentGenerationType.PDF_BASED,
  attachmentsFromTemplates: [],
};

import { AxiosError } from 'axios';
import { ReactNode, createContext, useContext } from 'react';
import { useQuery } from 'react-query';

import { DOCUMENTS_LIST } from 'constants/queries/documentsList';
import { getDocumentCount } from 'services/documents';

type State = {
  documentCount: number;
  refetchDocumentCount: () => void;
};

type Props = {
  children: ReactNode;
};

const DocumentContext = createContext<State>({
  documentCount: 0,
  refetchDocumentCount: () => void 0,
});

export const DocumentContextProvider = ({ children }: Props) => {
  const { data: documentCount, refetch: refetchDocumentCount } = useQuery<number, AxiosError>(
    [DOCUMENTS_LIST.COUNT],
    () => getDocumentCount(),
    {
      refetchInterval: 300000,
    }
  );

  return (
    <DocumentContext.Provider value={{ documentCount: documentCount || 0, refetchDocumentCount }}>
      {children}
    </DocumentContext.Provider>
  );
};

const useDocumentContext = () => useContext(DocumentContext);

export default useDocumentContext;

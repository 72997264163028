import { Alert } from '@profitowi/component-library';
import { AxiosError } from 'axios';

type Props = {
  error?: AxiosError | null | string;
};

const ErrorMessages = ({ error }: Props) => {
  if (typeof error === 'string') {
    return (
      <Alert className="my-2" type="error">
        {error}
      </Alert>
    );
  }

  if (typeof error?.response?.data === 'string' && error?.response?.data?.length) {
    return (
      <Alert className="my-2" type="error">
        {error?.response?.data}
      </Alert>
    );
  }

  return (
    <>
      {error?.response?.data?.errors?.map((error: string) => (
        <Alert className="my-2" type="error">
          {error}
        </Alert>
      )) ?? (
        <Alert className="my-2" type="error">
          {error?.message}
        </Alert>
      )}
    </>
  );
};

export default ErrorMessages;

import { Box, Modal } from '@profitowi/component-library';
import { useFormikContext } from 'formik';

import EditTemplateForm, {
  TemplateFormValues,
} from '../../../../Settings/components/TemplateCreator/EditTemplate/EditTemplateForm/EditTemplateForm';
import { CreateTemplateDocumentFormValues } from '../../CreateTemplateDocumentFormData/createTemplateDocumentFormValidationSchema';

interface Props {
  onNavigate: () => void;
}

const EditDocumentTemplateModal = ({ onNavigate }: Props) => {
  const { setFieldValue, values } = useFormikContext<CreateTemplateDocumentFormValues>();

  const handleSubmit = (formValues?: TemplateFormValues) => {
    if (formValues) {
      setFieldValue('selectedTemplate', formValues);
      setFieldValue('customContent', formValues.content);
      onNavigate();
    }
  };

  function onCancel() {
    onNavigate();
  }

  return (
    <Modal className="!max-w-[90%]" visible>
      <div className="w-full h-full overflow-hidden relative">
        <Box className="flex flex-col">
          <EditTemplateForm
            allowContentEditOnly
            editedItem={values.selectedTemplate}
            handleSubmit={handleSubmit}
            handleCancel={onCancel}
          />
        </Box>
      </div>
    </Modal>
  );
};

export default EditDocumentTemplateModal;

import { Button, Icon } from '@profitowi/component-library';
import { format } from 'date-fns';
import { Cell, Column } from 'react-table';

import { TemplateCreatorDataContent } from '../../../../../types/templateCreator';

export enum TemplateCreatorActions {
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
  ARCHIVE = 'ARCHIVE',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  COPY = 'COPY',
}

export const createColumn = (
  setEditedObjectAction: ({
    objectId,
    action,
  }: {
    objectId: number;
    action: TemplateCreatorActions;
  }) => void,
  isArchivedTemplatesView: boolean
): Column<TemplateCreatorDataContent>[] => {
  return [
    {
      Header: 'Id',
      accessor: 'id',
      disableSortBy: true,
    },
    {
      Header: 'Nazwa szablonu',
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: 'Rodzaj dokumentu',
      accessor: 'documentType',
      disableSortBy: true,
      Cell: ({
        row: {
          original: { documentType },
        },
      }: Cell<TemplateCreatorDataContent>) => {
        return <div className="whitespace-nowrap">{documentType}</div>;
      },
    },
    {
      Header: 'Data utworzenia',
      accessor: 'createdAt',
      Cell: ({
        row: {
          original: { createdAt },
        },
      }: Cell<TemplateCreatorDataContent>) => {
        return (
          <div className="whitespace-nowrap">
            {createdAt ? format(new Date(createdAt), 'dd-MM-yyyy HH:mm:ss') : ''}
          </div>
        );
      },
    },
    {
      Header: 'Ostatnia modyfikacja',
      accessor: 'lastModified',
      Cell: ({
        row: {
          original: { lastModified },
        },
      }: Cell<TemplateCreatorDataContent>) => {
        return (
          <div className="whitespace-nowrap">
            {lastModified ? format(new Date(lastModified), 'dd-MM-yyyy HH:mm:ss') : ''}
          </div>
        );
      },
    },
    {
      Header: 'Modyfikowane przez',
      accessor: 'modifiedBy',
      disableSortBy: true,
    },
    {
      Header: 'Opis',
      accessor: 'description',
      disableSortBy: true,
    },

    {
      id: 'edit',
      Header: 'Akcje',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<TemplateCreatorDataContent>) => {
        return (
          <div className="flex justify-end">
            {isArchivedTemplatesView ? (
              <>
                <div title="Usuń">
                  <Button
                    variant="light"
                    onPress={() => {
                      setEditedObjectAction({
                        objectId: original.id,
                        action: TemplateCreatorActions.DELETE,
                      });
                    }}>
                    <Icon custom={false} name="trash-fill" />
                  </Button>
                </div>
                <div title="Przywróć">
                  <Button
                    variant="light"
                    onPress={() => {
                      setEditedObjectAction({
                        objectId: original.id,
                        action: TemplateCreatorActions.RESTORE,
                      });
                    }}>
                    <Icon custom={false} name="arrow-counterclockwise" />
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div title="Edytuj">
                  <Button
                    variant="light"
                    onPress={() => {
                      setEditedObjectAction({
                        objectId: original.id,
                        action: TemplateCreatorActions.EDIT,
                      });
                    }}>
                    <Icon custom={false} name="three-dots-vertical" />
                  </Button>
                </div>
                <div title="Kopiuj">
                  <Button
                    variant="light"
                    onPress={() => {
                      setEditedObjectAction({
                        objectId: original.id,
                        action: TemplateCreatorActions.COPY,
                      });
                    }}>
                    <Icon custom={false} name="clipboard-plus" />
                  </Button>
                </div>
                <div title="Archiwizuj">
                  <Button
                    variant="light"
                    onPress={() => {
                      setEditedObjectAction({
                        objectId: original.id,
                        action: TemplateCreatorActions.ARCHIVE,
                      });
                    }}>
                    <Icon custom={false} name="archive-fill" />
                  </Button>
                </div>
              </>
            )}
          </div>
        );
      },
    },
  ];
};

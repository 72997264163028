import * as Yup from 'yup';

import { ADDRESS_TYPE } from 'constants/addressType';
import { COOPERATION_FORMS } from 'constants/cooperationForms';
import { ECONOMY_ACTIVITY_PARAM_TYPES } from 'constants/economyActivityParamTypes';
import { phoneNumberFormatError, requiredFieldMessage } from 'constants/requiredFieldMessage';
import { Addresses } from 'types/agent';

export const generateDefaultAddresses = (
  type?: string,
  billingAddress?: Addresses,
  homeAddress?: Addresses,
  correspondenceAddress?: Addresses
) => {
  const defaultHomeAddress = addresses.cast({ ...homeAddress, type: ADDRESS_TYPE.HOME_ADDRESS });
  const defaultBillingAddress = addresses.cast({
    ...billingAddress,
    type: ADDRESS_TYPE.BILLING_ADDRESS,
  });
  const defaultCorrespondenceAddress = addresses.cast({
    ...correspondenceAddress,
    type: ADDRESS_TYPE.CORRESPONDENCE_ADDRESS,
  });

  return type === COOPERATION_FORMS.COMPANY || type === COOPERATION_FORMS.SOLE_PROPRIETORSHIP
    ? [defaultBillingAddress, defaultCorrespondenceAddress]
    : [defaultHomeAddress, defaultCorrespondenceAddress];
};

const generateDefaultActivityParams = () =>
  Object.values([
    ECONOMY_ACTIVITY_PARAM_TYPES.VAT_RATE,
    ECONOMY_ACTIVITY_PARAM_TYPES.ECONOMY_ACTIVITY_STATE,
  ]).map((type) => economyActivityParams.cast({ type }));

const isAddressRequired = (addressType: string) =>
  addressType === ADDRESS_TYPE.BILLING_ADDRESS || addressType === ADDRESS_TYPE.HOME_ADDRESS;

const isIndividual = (type: string) => type === COOPERATION_FORMS.INDIVIDUAL;

const addresses = Yup.object().shape(
  {
    street: Yup.string()
      .when('type', {
        is: (type: string) => isAddressRequired(type),
        then: Yup.string().required(requiredFieldMessage),
      })
      .default(''),
    houseNumber: Yup.string()
      .when('type', {
        is: (type: string) => isAddressRequired(type),
        then: Yup.string().required(requiredFieldMessage),
      })
      .default(''),
    apartmentNumber: Yup.string().default('').nullable(),
    postalCode: Yup.string()
      .when('type', {
        is: (type: string) => isAddressRequired(type),
        then: Yup.string().required(requiredFieldMessage),
      })
      .default(''),
    city: Yup.string()
      .when('type', {
        is: (type: string) => isAddressRequired(type),
        then: Yup.string().required(requiredFieldMessage),
      })
      .default(''),
    type: Yup.string()
      .when('type', {
        is: (type: string) => isAddressRequired(type),
        then: Yup.string().required(requiredFieldMessage),
      })
      .default(''),
  },
  [['type', 'type']]
);

const generalShape = Yup.object({
  type: Yup.mixed()
    .oneOf(Object.values(COOPERATION_FORMS))
    .required(requiredFieldMessage)
    .default(''),
  validFrom: Yup.string().default(''),
  validTo: Yup.string().default(''),
  name: Yup.string()
    .when('type', {
      is: (type: string) => isIndividual(type),
      then: Yup.string().required(requiredFieldMessage),
    })
    .default(''),
  lastName: Yup.string()
    .when('type', {
      is: (type: string) => isIndividual(type),
      then: Yup.string().required(requiredFieldMessage),
    })
    .default(''),
  privateEmail: Yup.string().default(''),
  businessEmail: Yup.string().default(''),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{9,11}$/, phoneNumberFormatError)
    .default(''),
  valid: Yup.boolean().default(true),

  addresses: Yup.array()
    .of(addresses)
    .when(['type'], (type, addresses) => addresses.default(generateDefaultAddresses(type))),
});

const naturalPersonShape = Yup.object({
  pesel: Yup.string().isValidPesel().default(''),
  birthDate: Yup.string().default(''),
  birthPlace: Yup.string().default(''),
  education: Yup.string().default(''),
});

export const economyActivityParams = Yup.object({
  type: Yup.string().default(''),
  validFrom: Yup.string().default(''),
  validTo: Yup.string().default(''),
  value: Yup.string()
    .default('')
    .when('type', {
      is: (type: string) => type === ECONOMY_ACTIVITY_PARAM_TYPES.ECONOMY_ACTIVITY_STATE,
      then: Yup.string().required(requiredFieldMessage),
    }),
  description: Yup.string().default(''),
});

const businessActivityShape = Yup.object({
  companyName: Yup.string().required(requiredFieldMessage).default(''),
  nip: Yup.string().isValidNip().required(requiredFieldMessage).default(''),
  regon: Yup.string().isValidRegon().required(requiredFieldMessage).default(''),
  krs: Yup.string()
    .when('type', {
      is: (type: string) => type === COOPERATION_FORMS.COMPANY,
      then: Yup.string()
        .required(requiredFieldMessage)
        .matches(/[0-9]{10}/, 'Nieprawidłowy numer KRS.'),
    })
    .default(''),
  bankAccount: Yup.string().default(''),
  primaryBillingEmailAddress: Yup.string().default(''),

  economyActivityParams: Yup.array()
    .of(economyActivityParams)
    .when(['type'], (_, economyActivityParams) =>
      economyActivityParams.default(generateDefaultActivityParams())
    ),
});

export const validationSchema = Yup.lazy(({ type }) => {
  const cooperationShape =
    type === COOPERATION_FORMS.COMPANY || type === COOPERATION_FORMS.SOLE_PROPRIETORSHIP
      ? businessActivityShape
      : type === COOPERATION_FORMS.INDIVIDUAL
      ? naturalPersonShape
      : naturalPersonShape.concat(businessActivityShape);
  return generalShape.concat(cooperationShape);
});

const naturalAndBusinessSchema = naturalPersonShape.concat(businessActivityShape);
const Schema = generalShape.concat(naturalAndBusinessSchema);

export type GeneralCooperationShape = Yup.InferType<typeof generalShape>;
export type BusinessActivityShape = Yup.InferType<typeof businessActivityShape>;
export type NaturalPersonShape = Yup.InferType<typeof naturalPersonShape>;
export type ValidationSchemaShape = Yup.InferType<typeof Schema>;

import { Button, Icon, Loader, Modal, Table, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DOCUMENTS_LIST } from 'constants/queries/documentsList';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { usePagination } from 'hooks/usePagination';
import { getDocumentHistory } from 'services/documents';
import { DocumentHistory } from 'types/documents';
import { Page } from 'types/request';
import { decodeUriSortParams } from 'utils/table';

import { createColumn } from './columns';

const ArchiveDocumentsModal = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const documentId: number = state?.documentId;
  const documentName: string = state?.documentName;
  const { activeDictionaries } = useDictionaryContext();
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);
  const pagination = usePagination(10);

  useEffect(() => {
    if (!documentId) {
      navigate('..');
    }
  }, []);

  const { data, isError, isLoading, isFetching, isSuccess, error } = useQuery<
    Page<DocumentHistory>,
    AxiosError
  >(
    [DOCUMENTS_LIST.HISTORY, pagination.currentPage, pagination.perPage, sortBy, documentId],
    () =>
      getDocumentHistory(
        documentId,
        pagination.currentPage,
        pagination.perPage,
        decodeUriSortParams(sortBy)
      ),
    {
      keepPreviousData: true,
    }
  );

  const columns = useMemo(
    () =>
      createColumn({
        dictionaries: activeDictionaries,
      }),
    [activeDictionaries]
  );

  return (
    <Modal title="Historia dokumentu" visible className="text-center !space-y-4" size="5xl">
      <Text size="lg" className="pb-4">
        {documentName}
      </Text>
      {isLoading && <Loader className="h-12 w-12" />}

      {isError && (
        <div className="my-4">
          <ErrorMessages error={error} />
        </div>
      )}

      {isSuccess && data && (
        <div className="text-left">
          <Table
            pagination={pagination}
            sortBy={sortBy}
            data={data.content || []}
            columns={columns}
            isLoading={isFetching}
            totalPages={data.totalPages}
            onSortBy={handleSortBy}
          />
        </div>
      )}

      <div className="w-full flex justify-end">
        <Button className="flex space-x-2" onPress={() => navigate('..')}>
          <Icon name="x-lg" />
          <Text>Zamknij</Text>
        </Button>
      </div>
    </Modal>
  );
};

export default ArchiveDocumentsModal;

import * as Yup from 'yup';

import { TemplateCreatorDataContent } from 'types/templateCreator';

import {
  duplicateFileMessage,
  emptyFileInputMessage,
  requiredFieldMessage,
  requiredMultipleFieldsMessage,
} from '../../../../constants/requiredFieldMessage';
import {
  DocumentGenerationType,
  DocumentSignatureStatus,
  DocumentTemplateAssignmentReceiver,
  RecipientType,
  UserDocumentRole,
} from '../../../../types/documents';

export type CreateTemplateDocumentFormValues = CreateTemplateDocumentFormValuesStep1 &
  CreateTemplateDocumentFormValuesStep2 &
  CreateTemplateDocumentFormValuesStep3 &
  CreateTemplateDocumentFormValuesStep4 &
  CreateTemplateDocumentAdditionalData;

export type CreateTemplateDocumentFormValuesStep1 = Yup.InferType<
  typeof createTemplateDocumentFormValidationSchemaStep1
>;
export type CreateTemplateDocumentFormValuesStep2 = Yup.InferType<
  typeof createTemplateDocumentFormValidationSchemaStep2
>;
export type CreateTemplateDocumentFormValuesStep3 = Yup.InferType<
  typeof createTemplateDocumentFormValidationSchemaStep3
>;
export type CreateTemplateDocumentFormValuesStep4 = Yup.InferType<
  typeof createTemplateDocumentFormValidationSchemaStep4
>;

const UserGroup = (data: RecipientType): boolean => {
  return RecipientType.MULTIPLE_RECIPIENTS === data;
};

const SingleUser = (data: RecipientType): boolean => {
  return RecipientType.SINGLE_RECIPIENT === data;
};

const File = (data: RecipientType): boolean => {
  return RecipientType.CSV_FILE === data;
};

export interface CreateTemplateDocumentAdditionalData {
  customContent: string;
  documentGenerationType: DocumentGenerationType;
  selectedTemplate?: TemplateCreatorDataContent;
  assignments: DocumentTemplateAssignmentReceiver[];
  attachmentsFromTemplates: string[];
}

export const createTemplateDocumentFormValidationSchemaStep1 = Yup.object({
  documentName: Yup.string().required(requiredFieldMessage),
  documentType: Yup.string(),
  template: Yup.number().required(requiredFieldMessage),
  paperType: Yup.string().required(requiredFieldMessage),
  documentDate: Yup.date(),
  proxies: Yup.array().of(
    Yup.object({
      name: Yup.string(),
      id: Yup.string().required(requiredMultipleFieldsMessage),
      role: Yup.mixed<UserDocumentRole>()
        .oneOf(Object.values(UserDocumentRole))
        .required(requiredFieldMessage),
    })
  ),
});
export const createTemplateDocumentFormValidationSchemaStep2 = Yup.object({
  recipientContactMethod: Yup.mixed<RecipientType>()
    .oneOf(Object.values(RecipientType))
    .required(requiredFieldMessage),
  user: Yup.mixed().when('recipientContactMethod', {
    is: SingleUser,
    then: Yup.string().required(requiredFieldMessage),
  }),
  party: Yup.mixed().when('recipientContactMethod', {
    is: SingleUser,
    then: Yup.string(),
  }),
  schema: Yup.mixed().when('recipientContactMethod', {
    is: UserGroup,
    then: Yup.string().required(requiredFieldMessage),
  }),
  positions: Yup.mixed().when('recipientContactMethod', {
    is: UserGroup,
    then: Yup.string().required(requiredFieldMessage),
  }),
  csvFile: Yup.mixed().when('recipientContactMethod', {
    is: File,
    then: Yup.mixed().required(requiredFieldMessage),
  }),
});
export const createTemplateDocumentFormValidationSchemaStep3 = Yup.object({
  attachments: Yup.array().of(
    Yup.object({
      file: Yup.mixed(),
      uploadedFileId: Yup.number(),
    })
      .nullable()
      .required(emptyFileInputMessage)
      .test('unique-file-name', duplicateFileMessage, function (value, context) {
        const formValues = (context.options as any).from?.[(context.options as any).from.length - 1]
          .value;

        if (!formValues) return true;

        const { selectedTemplate, attachments } = formValues;

        if (!selectedTemplate?.attachments || !attachments) return true;

        const duplicateInTemplate = selectedTemplate.attachments.some(
          (templateAttachment: any) => templateAttachment.fileName === value?.file?.name
        );

        const duplicateInAttachments = attachments.filter(
          (attachment: any) => attachment.file?.name === value?.file?.name
        );

        return !duplicateInTemplate && duplicateInAttachments.length <= 1;
      })
  ),
});
export const createTemplateDocumentFormValidationSchemaStep4 = Yup.object({
  signature: Yup.mixed<DocumentSignatureStatus>()
    .oneOf(Object.values(DocumentSignatureStatus))
    .required(requiredFieldMessage),
  queue: Yup.mixed().when('signature', {
    is: (value: DocumentSignatureStatus) => value === DocumentSignatureStatus.SIGNED_DOCUMENT,
    then: Yup.array().of(
      Yup.object({
        recipients: Yup.array().of(Yup.string()).required(requiredFieldMessage),
      }).nullable()
    ),
  }),
});

import { Tab } from '@headlessui/react';
import { Header, Tabs } from '@profitowi/component-library';
import ReportModal from 'pages/Reports/components/ReportModal/ReportModal';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import useAgentRoleContext from 'hooks/useAgentRoleContext';
import { ReportType } from 'types/reports';
import { Roles } from 'types/user';

import Dictionaries from './components/Dictionaries/Dictionaries';
import Parameters from './components/Parameters/Parameters';
import SettingsSchemaCreator from './components/TemplateCreator/TemplateCreator';
import Templates from './components/Templates/Templates';
import Users from './components/Users/Users';

const categories = ['Użytkownicy', 'Parametry', 'Słowniki', 'Szablony', 'Kreator Szablonów'];

const Settings = () => {
  const { hasRole } = useAgentRoleContext();

  const settingCategories = useMemo(() => {
    let availableCategories: string[] = [];
    if (
      hasRole(Roles.ADMIN) ||
      hasRole(Roles.SUPER_ADMIN) ||
      (hasRole(Roles.COMMISSION) && hasRole(Roles.RECORDS_MANAGEMENT))
    )
      availableCategories = categories.filter((item) => item !== 'Kreator Szablonów');
    else if (hasRole(Roles.COMMISSION))
      availableCategories = categories.filter((item) => item !== 'Użytkownicy');
    else if (
      hasRole(Roles.REPORTS) ||
      hasRole(Roles.CONTRACTOR) ||
      hasRole(Roles.RECORDS_MANAGEMENT)
    ) {
      availableCategories = ['Użytkownicy'];
    }

    if (hasRole(Roles.DOCUMENTS_MANAGEMENT_TEMPLATE)) availableCategories.push('Kreator Szablonów');

    return availableCategories;
  }, [hasRole]);

  return (
    <div className="flex-1 p-8 space-y-6 overflow-hidden">
      <div className="flex flex-col justify-between lg:flex-row lg:space-y-0 space-y-7">
        <Header as="h1" size="xl" weight="semibold">
          Ustawienia
        </Header>
      </div>

      <Tabs variant="light" categories={settingCategories}>
        {(hasRole(Roles.ADMIN) ||
          hasRole(Roles.SUPER_ADMIN) ||
          hasRole(Roles.REPORTS) ||
          hasRole(Roles.CONTRACTOR) ||
          hasRole(Roles.RECORDS_MANAGEMENT)) && (
          <Tab.Panel>
            <Users />
          </Tab.Panel>
        )}
        {(hasRole(Roles.ADMIN) || hasRole(Roles.SUPER_ADMIN) || hasRole(Roles.COMMISSION)) && (
          <Tab.Panel>
            <Parameters />
          </Tab.Panel>
        )}
        {(hasRole(Roles.ADMIN) || hasRole(Roles.SUPER_ADMIN) || hasRole(Roles.COMMISSION)) && (
          <Tab.Panel>
            <Dictionaries />
          </Tab.Panel>
        )}
        {(hasRole(Roles.ADMIN) || hasRole(Roles.SUPER_ADMIN) || hasRole(Roles.COMMISSION)) && (
          <Tab.Panel>
            <Templates />
          </Tab.Panel>
        )}
        {hasRole(Roles.DOCUMENTS_MANAGEMENT_TEMPLATE) && (
          <Tab.Panel>
            <SettingsSchemaCreator />
          </Tab.Panel>
        )}
      </Tabs>

      <Routes>
        <Route
          path="generateReport/*"
          element={<ReportModal initialReportType={ReportType.USERS_LIST} />}
        />
      </Routes>
    </div>
  );
};

export default Settings;

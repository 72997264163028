export const VariablesListMap = new Map([
  ['Data', ['${data}']],
  ['Pełnomocnicy', ['${pelnomocnicy}']],
  [
    'Kontrahent - odbiorca',
    [
      '${pr}',
      '${imie}',
      '${nazwisko}',
      '${pesel}',
      '${nip}',
      '${regon}',
      '${telefon}',
      '${email}',
      '${adres_zamieszkania}',
      '${adres_korespondencyjny}',
      '${adres_dzialalnosci}',
      '${biuro}',
      '${nazwa_firmy}',
      '${typ}',
      '${numer_konta}',
      '${rau}',
    ],
  ],
  [
    'Kontrahent - strona',
    [
      '${strona_pr}',
      '${strona_imie}',
      '${strona_nazwisko}',
      '${strona_pesel}',
      '${strona_nip}',
      '${strona_regon}',
      '${strona_telefon}',
      '${strona_email}',
      '${strona_adres_zamieszkania}',
      '${strona_adres_korespondencyjny}',
      '${strona_adres_dzialalnosci}',
      '${strona_biuro}',
      '${strona_nazwa_firmy}',
      '${strona_typ}',
      '${strona_numer_konta}',
      '${strona_rau}',
    ],
  ],
]);

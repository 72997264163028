import { Input, Loader, Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import useDocumentContext from 'hooks/useDocumentContext';
import { signCodeDocuments, signDocuments } from 'services/documents';
import { SignDocumentResponse } from 'types/documents';
import { getOsData } from 'utils/clientData';

type Props = {
  refetch: () => void;
};

const SignDocumentsModal = ({ refetch }: Props) => {
  const { refetchDocumentCount } = useDocumentContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [confirmationCode, setConfirmationCode] = useState<string>();
  const [customError, setCustomError] = useState<string>();
  const [inputError, setInputError] = useState<string>();
  const [ip, setIP] = useState('');
  const documentIds: number[] = state?.documentIds;

  const handleConfirmationCodeInput = useCallback((value: string) => {
    setConfirmationCode(value.slice(0, 6));
  }, []);

  const {
    data: dataSignCode,
    error: errorSignCode,
    isError: isErrorSignCode,
    isLoading: isLoadingSignCode,
    mutate: mutateSignCode,
  } = useMutation<
    AxiosResponse<SignDocumentResponse>,
    AxiosError<{ date: string; attemptNumber: number }>
  >(() => signCodeDocuments(documentIds || []));

  const {
    error: errorSignDocument,
    isError: isErrorSignDocument,
    isLoading: isLoadingSignDocument,
    mutate: mutateSignDocument,
  } = useMutation<AxiosResponse, AxiosError>(
    () =>
      signDocuments({
        otpCode: confirmationCode as string,
        documentIds: documentIds || [],
        ipAddress: ip,
        browserMetadata: navigator.userAgent,
      }),
    {
      onSuccess: () => {
        refetch();
        navigate('..');
        refetchDocumentCount();
      },
    }
  );

  useEffect(() => {
    if (!documentIds?.length) {
      return setCustomError('Nie wybrano żadnego dokumentu.');
    }

    mutateSignCode();
  }, [documentIds]);

  function onSubmit() {
    if (confirmationCode?.length !== 6) {
      return setInputError('Podaj poprawny kod SMS.');
    }

    setInputError('');
    if (!!confirmationCode) {
      mutateSignDocument();
    }
  }

  useEffect(() => {
    getOsData().then((ip) => setIP(ip));
  }, []);

  return (
    <Modal
      title={`Podpisz dokument${documentIds?.length > 1 ? 'y' : ''}`}
      visible
      className="text-center !space-y-8">
      {isLoadingSignCode ? (
        <>
          <Loader className="h-6 w-6" />
        </>
      ) : (
        <>
          <div className="flex flex-col">
            <Text size="lg" className="text-center">
              Wprowadź kod {dataSignCode?.data?.dailyCodeNumber || '-'}
            </Text>
            <Text size="lg" className="text-center">
              Uwaga! Kod jest ważny do{' '}
              {!!dataSignCode?.data
                ? format(new Date(dataSignCode?.data?.validUntil), 'HH:mm:ss')
                : '-'}
            </Text>
          </div>

          <div className="flex flex-col">
            <Input
              isDisabled={isErrorSignCode}
              errorMessage={inputError}
              className={` ${inputError && 'border-red-700'} hide-number-input-arrows`}
              label="Kod SMS"
              type="number"
              onChange={handleConfirmationCodeInput}
              value={confirmationCode}
            />
            {!!inputError && (
              <Text size="sm" className="text-red-700 flex">
                {inputError}
              </Text>
            )}
          </div>

          <ModalActions
            disableSubmit={!!customError || isErrorSignCode}
            confirmLabel="Zatwierdź"
            isLoading={isLoadingSignDocument}
            onCancel={() => navigate('..')}
            onConfirm={onSubmit}
          />
          {(isErrorSignDocument || isErrorSignCode || !!customError) && (
            <ErrorMessages error={errorSignDocument || errorSignCode || customError} />
          )}
        </>
      )}
    </Modal>
  );
};

export default SignDocumentsModal;

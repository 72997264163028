import { Button, Text } from '@profitowi/component-library';
import React from 'react';

import { VariablesListMap } from './VariablesListMap';

function VariablesList() {
  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <div className="flex py-4 flex-col gap-2 h-full overflow-y-scroll absolute right-0 top-0">
      <Text weight="bold" size="lg">
        Zmienne
      </Text>

      {Array.from(VariablesListMap.entries()).map(([key, values]) => (
        <div key={key}>
          <Text size="md" className="mb-2">
            {key}
          </Text>
          <div className="flex flex-col gap-2">
            {values.map((value, index) => (
              <Button
                size="sm"
                variant="outline-primary"
                key={index}
                onPress={() => handleCopy(value)}>
                {value}
              </Button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default VariablesList;

import { Box, Button, Header, Loader, Table } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { FILE } from 'constants/queries/file';
import { usePagination } from 'hooks/usePagination';
import { downloadFile, getFileList } from 'services/file';
import { FileFromBackend, FileTypes } from 'types/file';
import { Page } from 'types/request';
import { saveFile } from 'utils/file';
import { decodeUriSortParams } from 'utils/table';

import { createColumns } from './columns';
import DownloadFileModal from './components/DownloadFileModal';

type Props = {
  isDashboardVersion?: boolean;
};

const Files = ({ isDashboardVersion }: Props) => {
  const navigate = useNavigate();
  const pagination = usePagination(isDashboardVersion ? 5 : 10);
  const [downloadedFile, setDownloadedFile] = useState<FileFromBackend>();
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);

  const { data, isError, isFetching, isSuccess, isLoading, error, refetch } = useQuery<
    Page<FileFromBackend>,
    AxiosError
  >(
    [FILE.SETTLEMENTS_FILES, pagination.currentPage, pagination.perPage, sortBy],
    () =>
      getFileList(
        [
          FileTypes.ACCOUNTING_FILE,
          FileTypes.ING_PAYMENTS_FILE,
          FileTypes.SETTLEMENT_DOCUMENTS_ARCHIVE,
          FileTypes.IRZB_ARCHIVE,
        ],
        pagination.currentPage,
        pagination.perPage,
        isDashboardVersion,
        decodeUriSortParams(sortBy)
      ),
    { keepPreviousData: true }
  );

  const { refetch: refetchDownloadDocument } = useQuery<AxiosResponse<Blob>, AxiosError>(
    [FILE.DOWNLOAD, downloadedFile],
    () =>
      downloadFile(
        downloadedFile?.fileId as NonNullable<number>,
        downloadedFile?.fileType as NonNullable<FileTypes>
      ),
    {
      enabled: !!downloadedFile,
      onSuccess: (data) => {
        if (data.status === 202) {
          navigate('download');
        } else {
          saveFile(data.data, downloadedFile?.fileName as NonNullable<string>);
          refetch();
        }
      },
      onError: () => {
        refetch();
      },
    }
  );

  const onFileDownload = (file: FileFromBackend) => {
    setDownloadedFile(file);
  };

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between items-center mb-6">
          <Header as="h4" size="lg" weight="semibold">
            {isDashboardVersion ? 'Ostatnio zaimportowane pliki' : 'Pliki'}
          </Header>
          <div className="flex space-x-4">
            {isDashboardVersion && (
              <Button variant="secondary" onPress={() => navigate('/settlements/files')}>
                Więcej
              </Button>
            )}
            {!isDashboardVersion && (
              <Button variant="secondary" onPress={() => refetch()}>
                Odśwież
              </Button>
            )}
          </div>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && (
          <Box.FullWidth>
            <Table
              columns={createColumns(onFileDownload)}
              data={data?.content || []}
              sortBy={sortBy}
              onSortBy={handleSortBy}
              isLoading={isFetching}
              totalPages={isDashboardVersion ? undefined : data?.totalPages}
              pagination={isDashboardVersion ? undefined : pagination}
            />
          </Box.FullWidth>
        )}
      </Box>

      <Routes>
        <Route
          path="download"
          element={
            <DownloadFileModal
              documentId={downloadedFile?.fileId}
              refetch={refetchDownloadDocument}
              setDownloadedFile={setDownloadedFile}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Files;

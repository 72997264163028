import { Box, Button, Input, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import ChangePasswordForm from 'pages/Settings/components/Users/ChangePasswordForm/ChangePasswordForm';
import EditUserForm from 'pages/Settings/components/Users/EditUserForm/EditUserForm';
import useChangePassword from 'pages/Settings/components/common/hooks/useChangePassword';
import useEditUser from 'pages/Settings/components/common/hooks/useEditUser';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import InsetLoader from 'components/InsetLoader/InsetLoader';
import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';
import { getUserDetails } from 'services/user';
import { ChangePasswordPayload, EditUserPayload, User } from 'types/user';

function MyAccount() {
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [editUserModalVisible, setEditUserModalVisible] = useState(false);
  const { translate } = useDictionaryContext();

  const {
    data: user,
    isError,
    isLoading,
    error,
    refetch,
  } = useQuery<User, AxiosError>('userDetails', () => getUserDetails());

  const editUser = useEditUser(user?.keycloakId || '', refetch, () => {
    setEditUserModalVisible(false);
  });

  const changePassword = useChangePassword();

  const onPasswordSave = (payload: ChangePasswordPayload) => {
    changePassword.mutate({
      password: payload.password,
      userId: user?.keycloakId || '',
      temporary: false,
    });
  };

  const onEditUserSave = (payload: EditUserPayload) => {
    editUser.mutate(payload);
  };

  const onEditUserModalClose = () => {
    setEditUserModalVisible(false);
  };

  const onChangePasswordModalClose = () => {
    const { isSuccess, reset } = changePassword;

    setPasswordModalVisible(false);
    isSuccess && reset();
  };

  return (
    <div className="flex-1 p-8 space-y-6 overflow-hidden">
      <Box className="space-y-6 relative p-10">
        <Text weight="bold" size="xl" className="-mb-6">
          Konto użytkownika
        </Text>
        {isLoading && <InsetLoader />}

        <div className="flex w-full">
          <div className="flex flex-col space-y-4 w-full">
            <div className="flex align-center w-full">
              <label htmlFor="username" className="mr-6 w-48 flex items-center">
                Nazwa użytkownika:{' '}
              </label>
              <div className="min-w-[32rem]">
                <Input
                  name="username"
                  value={user?.username?.toUpperCase() || ' '}
                  isDisabled
                  aria-label="Username"
                />
              </div>
            </div>

            <div className="flex align-center w-full">
              <label htmlFor="firstName" className="mr-6 w-48 flex items-center">
                Imię:{' '}
              </label>
              <div className="min-w-[32rem]">
                <Input
                  className="w-full"
                  name="firstName"
                  value={user?.firstName || ' '}
                  isDisabled
                  aria-label="First name"
                />
              </div>
            </div>

            <div className="flex align-center w-full">
              <label htmlFor="lastName" className="mr-6 w-48 flex items-center">
                Nazwisko:{' '}
              </label>
              <div className="min-w-[32rem]">
                <Input
                  className="w-full"
                  name="lastName"
                  value={user?.lastName || ' '}
                  aria-label="Last name"
                  isDisabled
                />
              </div>
            </div>

            <div className="flex align-center w-full">
              <label htmlFor="email" className="mr-6 w-48 flex items-center">
                E-mail:{' '}
              </label>
              <div className="min-w-[32rem]">
                <Input
                  className="w-full"
                  name="email"
                  value={user?.email || ' '}
                  isDisabled
                  aria-label="E-mail"
                />
              </div>
            </div>

            <div className="flex align-center w-full">
              <label htmlFor="phoneNumber" className="mr-6 w-48 flex items-center">
                Numer telefonu:{' '}
              </label>
              <div className="min-w-[32rem]">
                <Input
                  className="w-full"
                  name="phoneNumber"
                  value={user?.phoneNumber || ' '}
                  isDisabled
                  aria-label="Phone Number"
                />
              </div>
            </div>

            <div className="flex align-center w-full">
              <label htmlFor="roles" className="mr-6 w-48 flex items-center">
                Role:{' '}
              </label>
              <div className="min-w-[32rem]">
                <Input
                  className="w-full"
                  name="roles"
                  aria-label="Role"
                  value={
                    user?.roles
                      ?.map((role) => translate(DICTIONARY_TYPES.KEYCLOAK_ROLES, role))
                      .join(', ') || ' '
                  }
                  isDisabled
                />
              </div>
            </div>

            <div className="flex align-center w-full">
              <label htmlFor="enabled" className="mr-6 w-48 flex items-center">
                Status:{' '}
              </label>
              {user?.enabled ? (
                <div className="flex items-center space-x-1">
                  <i className="bi bi-check text-2xl text-green-600"></i>
                  <Text size="md">Aktywny</Text>
                </div>
              ) : (
                <div className="flex items-center space-x-1">
                  <i className="bi bi-x text-2xl text-rose-800"></i>
                  <Text size="md">Nieaktywny</Text>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex space-x-8 justify-end mr-10">
          <Button onPress={() => setEditUserModalVisible(true)}>Edytuj dane</Button>
          <Button onPress={() => setPasswordModalVisible(true)}>Zmień hasło</Button>
        </div>

        {isError && <ErrorMessages error={error} />}
      </Box>
      {user && passwordModalVisible && (
        <ChangePasswordForm
          visible={passwordModalVisible}
          setVisible={setPasswordModalVisible}
          onSave={onPasswordSave}
          closeModal={onChangePasswordModalClose}
          changePasswordError={changePassword.error}
          editedItem={user}
          isSuccess={changePassword.isSuccess}
        />
      )}
      {user && editUserModalVisible && (
        <EditUserForm
          visible={editUserModalVisible}
          setVisible={setEditUserModalVisible}
          onSave={onEditUserSave}
          closeModal={onEditUserModalClose}
          editedItem={user}
        />
      )}
    </div>
  );
}

export default MyAccount;

import { DocumentFiltersFormValues } from './validationSchema';

export const documentFiltersFormInitialValues: DocumentFiltersFormValues = {
  agentCode: '',
  recipientName: '',
  documentName: '',
  templateId: undefined,
  documentTypeFromDict: undefined,
  dateFrom: '',
  dateTo: '',
  isArchived: false,
  documentStatus: undefined,
  mySigningStatus: undefined,
  createdBy: undefined,
};

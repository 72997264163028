import { Button, Header, Popover, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import GroupTransferModal from 'pages/Agent/components/GroupTransferModal/GroupTransferModal';
import useAgentId from 'pages/Agent/useAgentId';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { BlockadeType } from 'constants/blockadeType';
import { COOPERATIONS_STATUS } from 'constants/cooperationStatus';
import { USER } from 'constants/queries/user';
import { deleteAgent, markAgentAsInDebtRecovery } from 'services/agent';
import { getUserByAgentId } from 'services/user';
import { AgentDetailsBlockade, EconomyActivities } from 'types/agent';
import { User } from 'types/user';

import AgentBusinessIdentifiersForm from '../AgentBusinessIdentifiersForm/AgentBusinessIdentifiersForm';
import CooperationForm from '../Cooperation/CooperationForm';
import EndCooperationForm from '../EndCooperationForm/EndCooperationForm';
import GeneratePaymentForm from '../GeneratePaymentForm/GeneratePaymentForm';

type Props = {
  economyActivities?: EconomyActivities;
  cooperationStatus: string;
  blockades: AgentDetailsBlockade[];
  agentCode?: string;
  debtRecovery?: boolean;
  refetchAgent: () => void;
};

const AgentHeader = ({
  economyActivities,
  cooperationStatus,
  blockades,
  agentCode,
  debtRecovery,
  refetchAgent,
}: Props) => {
  const [businessIdentifierModal, setBusinessIdentifierModal] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [groupTransferModalVisible, setGroupTransferModalVisible] = useState(false);
  const [endCooperationModalVisible, setEndCooperationModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [changeCooperationModalVisible, setChangeCooperationModalVisible] = useState(false);
  const [inDebtModalvisible, setInDebtModalVisible] = useState(false);

  const agentId = useAgentId();
  const navigate = useNavigate();

  const {
    error: deleteAgentError,
    isError: isDeleteAgentError,
    isLoading: isDeleteAgentLoading,
    mutate: mutateDeleteAgent,
  } = useMutation<AxiosResponse, AxiosError>(() => deleteAgent(agentId), {
    onSuccess: () => {
      setDeleteModalVisible(false);
      navigate('/records');
    },
  });

  // const { data: policies } = useQuery<Page<PolicyWallet>, AxiosError>(
  //   [POLICES.AGENT_WALLET, agentId],
  //   () => getAgentWallet(agentId),
  //   {
  //     keepPreviousData: true,
  //   }
  // );

  const {
    error: inDebtError,
    isError: isInDebtError,
    isLoading: isInDebtLoading,
    mutate: mutateInDebt,
  } = useMutation<AxiosResponse, AxiosError>(() => markAgentAsInDebtRecovery(agentId), {
    onSuccess: () => {
      setInDebtModalVisible(false);
      refetchAgent();
    },
  });

  const isPayoutBlockadeActive = useMemo(
    () =>
      blockades
        .filter((blockade) => blockade.type === BlockadeType.PAYOUT_BLOCKER)
        .some((blockade) => blockade.valid),
    [blockades]
  );

  const { data, isSuccess } = useQuery<User, AxiosError>(
    [USER.USER, agentId],
    () => getUserByAgentId(agentId),
    {
      staleTime: Infinity,
      retry: false,
    }
  );

  // const { data: user } = useQuery<User, AxiosError>('userDetails', () => getUserDetails());

  // const hasSuperiorRole = user?.roles.find(
  //   (item) => item === Roles.ADMIN || item === Roles.COMMISSION
  // );

  return (
    <div className="space-y-0 sm:space-y-4">
      <div className="flex flex-wrap justify-between items-center">
        <div className="flex flex-col">
          <Header as="h1" size="xl" weight="semibold">
            {economyActivities?.name?.concat(' ', economyActivities?.lastName)}, {agentCode}
          </Header>
          <div className="flex">
            <Link to="/records">
              <Text size="sm">KONTRAHENCI /</Text>
            </Link>
            <Text size="sm" className="text-secondary-blue">
              SZCZEGÓŁY KONTRAHENTÓW
            </Text>
            {debtRecovery && (
              <div>
                <Text size="sm" className="text-rose-600" weight="bold">
                  <i className='bi before:text-2xl before:-mt-[0.55rem] before:content-["\f33c"] bi-exclamation align-top'></i>
                  WINDYKACJA
                </Text>
              </div>
            )}
          </div>
        </div>
        <div className="flex space-x-4">
          <Button
            variant="outline-primary"
            isDisabled={isPayoutBlockadeActive}
            onPress={() => setPaymentModalVisible(true)}>
            Generuj płatność
          </Button>
          {data && isSuccess && (
            <Button
              variant="outline-primary"
              isDisabled={false}
              onPress={() =>
                (window.location.href = `${window.location.protocol}//${window.location.host}/portal-agenta?keycloakId=${data.keycloakId}`)
              }>
              Podszyj się pod Agenta
            </Button>
          )}
          <Button
            onPress={() => setBusinessIdentifierModal(true)}
            size="sm"
            variant="outline-primary">
            <span>Edytuj NIP/REGON</span>
            <i className="bi bi-pencil-square ml-2" />
          </Button>
          <Popover>
            <Popover.Button as={Button} variant="outline-primary">
              <i className="bi bi-three-dots-vertical" />
            </Popover.Button>
            <Popover.Panel>
              <div className="flex flex-col space-y-2 items-stretch">
                <Button variant="secondary" onPress={() => setChangeCooperationModalVisible(true)}>
                  Zmień formę współpracy
                </Button>
                <Button
                  isDisabled={cooperationStatus === COOPERATIONS_STATUS.FINISHED && !debtRecovery}
                  variant="secondary"
                  onPress={() => setEndCooperationModalVisible(true)}>
                  Zakończ współpracę
                </Button>
                <Button
                  variant="secondary"
                  onPress={() => setInDebtModalVisible(true)}
                  isDisabled={cooperationStatus === COOPERATIONS_STATUS.FINISHED && debtRecovery}>
                  Przekaż do windykacji
                </Button>
                <Button variant="secondary" onPress={() => setDeleteModalVisible(true)}>
                  Usuń kontrahenta
                </Button>
              </div>
            </Popover.Panel>
          </Popover>
        </div>

        {changeCooperationModalVisible && (
          <CooperationForm
            economyActivity={economyActivities}
            refetchAgent={refetchAgent}
            setVisible={setChangeCooperationModalVisible}
          />
        )}

        {inDebtModalvisible && (
          <ConfirmationModal
            confirmMessage="Czy na pewno chcesz przekazać kontrahenta do windykacji ?"
            isError={isInDebtError}
            isLoading={isInDebtLoading}
            error={inDebtError}
            mutate={mutateInDebt}
          />
        )}

        {endCooperationModalVisible && (
          <EndCooperationForm
            visible={endCooperationModalVisible}
            setVisible={setEndCooperationModalVisible}
            refetchAgent={refetchAgent}
          />
        )}

        {deleteModalVisible && (
          <ConfirmationModal
            confirmMessage="Czy na pewno usunąć kontrahenta ?"
            isError={isDeleteAgentError}
            isLoading={isDeleteAgentLoading}
            error={deleteAgentError}
            mutate={mutateDeleteAgent}
          />
        )}

        {groupTransferModalVisible && (
          <GroupTransferModal
            withDescription={true}
            closeModal={() => setGroupTransferModalVisible(false)}
            onSuccess={() => setEndCooperationModalVisible(true)}
          />
        )}

        {businessIdentifierModal && (
          <AgentBusinessIdentifiersForm
            visible={businessIdentifierModal}
            setVisible={setBusinessIdentifierModal}
            refetch={refetchAgent}
            economyActivity={economyActivities}
          />
        )}

        {paymentModalVisible && (
          <GeneratePaymentForm visible={paymentModalVisible} setVisible={setPaymentModalVisible} />
        )}
      </div>
      <Outlet />
    </div>
  );
};

export default AgentHeader;

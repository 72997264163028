export const DICTIONARY_TYPES = {
  ACCOUNTING_DOCUMENT_STATUS: 'ACCOUNTING_DOCUMENT_STATUS',
  ACCOUNTING_DOCUMENT_TYPE: 'ACCOUNTING_DOCUMENT_TYPE',
  ACTIVITY_RANGE: 'ACTIVITY_RANGE',
  ADDRESS_TYPE: 'ADDRESS_TYPE',
  ADVANCE_PAYMENT_TYPE: 'ADVANCE_PAYMENT_TYPE',
  AGREEMENT_TYPE: 'AGREEMENT_TYPE',
  APPLICATION_CONFIGURATION: 'APPLICATION_CONFIGURATION',
  BILLING_EVENT_TYPE: 'BILLING_EVENT_TYPE',
  BILLING_ITEM_STATUS: 'BILLING_ITEM_STATUS',
  BLOCKER_TYPE: 'BLOCKER_TYPE',
  BONUS_CODE_NAME: 'BONUS_CODE_NAME',
  BONUS_STATUS: 'BONUS_STATUS',
  BONUS_TYPE: 'BONUS_TYPE',
  COMMISSION_CONDITION: 'COMMISSION_CONDITION',
  COMMISSION_IMPORT_STATUS: 'COMMISSION_IMPORT_STATUS',
  COMMISSION_SCHEMA_NAME: 'COMMISSION_SCHEMA_NAME',
  COMMISSION_SCHEMA_STRUCTURE: 'COMMISSION_SCHEMA_STRUCTURE',
  COMMUNICATION_REASON: 'COMMUNICATION_REASON',
  COMMUNICATION_STATUS: 'COMMUNICATION_STATUS',
  CONTRACT_STATUS: 'CONTRACT_STATUS',
  COOPERATION_STATUS: 'COOPERATION_STATUS',
  DOCUMENT_POSITION_NAME: 'DOCUMENT_POSITION_NAME',
  ECONOMY_ACTIVITY_STATE: 'ECONOMY_ACTIVITY_STATE',
  ECONOMY_ACTIVITY_TYPE: 'ECONOMY_ACTIVITY_TYPE',
  EDUCATION: 'EDUCATION',
  FILE_STATUS: 'FILE_STATUS',
  FILE_TYPE: 'FILE_TYPE',
  INSURANCE_COMPANY: 'INSURANCE_COMPANY',
  KEYCLOAK_ROLES: 'KEYCLOAK_ROLES',
  MACRO_REGION: 'MACRO_REGION',
  MESSAGE_TYPE: 'MESSAGE_TYPE',
  OFFICE: 'OFFICE',
  OTHER_PAYMENT_DESCRIPTION: 'OTHER_PAYMENT_DESCRIPTION',
  PARAM_TYPE: 'PARAM_TYPE',
  PAYMENT_STATUS: 'PAYMENT_STATUS',
  PAYOUT_POSITION_TYPE: 'PAYOUT_POSITION_TYPE',
  POSITION: 'POSITION',
  POSITION_TYPE: 'POSITION_TYPE',
  PRODUCT_GROUP: 'PRODUCT_GROUP',
  REGION: ' REGION',
  SETTLEMENT_STATUS: 'SETTLEMENT_STATUS',
  SETTLEMENT_TYPE: 'SETTLEMENT_TYPE',
  VAT_RATE: 'VAT_RATE',
  MESSAGE_TEMPLATE_TYPE: 'MESSAGE_TEMPLATE_TYPE',
  MESSAGE_STATUS: 'MESSAGE_STATUS',
  SETTLEMENT_STAGE: 'SETTLEMENT_STAGE',
  FILE_PARAMS: 'FILE_PARAMS',
  REPORT_TYPE: 'REPORT_TYPE',
  DOCUMENT_TYPE: 'DOCUMENT_TYPE',
  DOCUMENT_STATUS: 'DOCUMENT_STATUS',
  SIGNING_STATUS: 'SIGNING_STATUS',
  DOCUMENT_HISTORY_ACTION: 'DOCUMENT_HISTORY_ACTION',
  USER_DOCUMENT_ROLE: 'USER_DOCUMENT_ROLE',
};

import qs from 'qs';

import {
  DocumentHistory,
  DraftDocumentUpdateRequest,
  GetDocumentTemplateResponse,
  GetDocumentsRequest,
  RecipientsCsvResponse,
  SignDocumentRequest,
} from 'types/documents';
import { Page } from 'types/request';

import { aperolApi } from '../utils/axios';

export const draftTemplateDocument = async (data: FormData) => {
  return await aperolApi.post<void>(`/documents/draft`, data);
};

export const previewTemplateDocument = async (data: FormData) => {
  return await aperolApi.post<void>(`/documents/preview`, data, {
    responseType: 'blob',
  });
};

export const uploadDocumentRecipientsCsv = async (formData: FormData) => {
  return await aperolApi.post<RecipientsCsvResponse[]>(`/documents/recipientsFile`, formData);
};

export const getDocumentsList = async (
  page: number,
  size: number,
  params?: GetDocumentsRequest,
  sort?: string
) => {
  const { data } = await aperolApi.get(`/documents/search`, {
    params: { page, size, sort, ...params },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    },
  });

  return data;
};

export const deleteDocuments = async (documentIds: number[]) => {
  return await aperolApi.delete(`/documents`, { data: documentIds });
};

export const sendDocuments = async (documentIds: number[]) => {
  return await aperolApi.post(`/documents/processDocuments`, documentIds);
};

export const archiveDocuments = async (documentIds: number[]) => {
  return await aperolApi.patch(`/documents/archive`, documentIds);
};

export const generateReportDocuments = async (payload: GetDocumentsRequest) => {
  return await aperolApi.post(`/documents/generateReport`, { data: payload });
};

export const sendReminderDocuments = async (documentIds: number[]) => {
  return await aperolApi.post(`/documents/remind`, documentIds);
};

export const downloadDocuments = async (documentIds: number[]) => {
  const data = await aperolApi.get(`/documents/download`, {
    params: { documentIds },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    responseType: 'blob',
  });

  return { ...data, isPdfFile: documentIds.length === 1 };
};

export const signDocuments = async (payload: SignDocumentRequest) => {
  return await aperolApi.post(`/documents/sign`, payload);
};

export const signCodeDocuments = async (payload: number[]) => {
  return await aperolApi.post(`/documents/signCode`, payload);
};

export const restoreDocuments = async (documentIds: number[]) => {
  return await aperolApi.patch(`/documents/unarchive`, documentIds);
};

export const updateDocument = async (payload: DraftDocumentUpdateRequest) => {
  return await aperolApi.patch(`/documents/draft`, payload);
};

export const getDocumentCount = async () => {
  const { data } = await aperolApi.get<number>(`/documents/count`);

  return data;
};

export const getDocument = async (documentId: number) => {
  const { data } = await aperolApi.get<GetDocumentTemplateResponse>(`/documents/${documentId}`);

  return data;
};

export const getDocumentHistory = async (
  documentId: number,
  page: number,
  size: number,
  sort?: string
) => {
  const { data } = await aperolApi.get<Page<DocumentHistory>>(`/documents/history/${documentId}`, {
    params: { page, size, sort },
  });

  return data;
};

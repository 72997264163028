import { Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { restoreDocuments } from 'services/documents';

type Props = {
  refetch: () => void;
};

const RestoreDocumentsModal = ({ refetch }: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [customError, setCustomError] = useState<string>();
  const documentIds: number[] = state?.documentIds;

  const { error, isError, isLoading, mutate } = useMutation<AxiosResponse, AxiosError>(
    () => restoreDocuments(documentIds),
    {
      onSuccess: () => {
        refetch();
        navigate('..');
      },
    }
  );

  useEffect(() => {
    if (!documentIds?.length) {
      setCustomError('Nie wybrano żadnego dokumentu.');
    }
  }, [documentIds]);

  return (
    <Modal title="Potwierdź przywrócenie" visible className="text-center !space-y-8">
      <div className="flex flex-col">
        <Text size="lg" className="text-center">
          Czy na pewno chcesz przywrócić do użytku zaznaczone dokumenty?
        </Text>
        <Text size="lg" className="text-center">
          Po potwierdzeniu komunikatu pozycja/pozycje zostaną przeniesione do zakładki “Aktualne”.
        </Text>
      </div>

      <ModalActions
        disableSubmit={!!customError}
        confirmLabel="Zatwierdź"
        isLoading={isLoading}
        onCancel={() => navigate('..')}
        onConfirm={() => mutate()}
      />
      {(isError || customError) && <ErrorMessages error={error || customError} />}
    </Modal>
  );
};

export default RestoreDocumentsModal;

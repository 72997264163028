import * as Yup from 'yup';

import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { DocumentStatus, SigningStatus } from 'types/documents';

export type DocumentFiltersFormValues = Yup.InferType<typeof documentFiltersFormValidationSchema>;

export const documentFiltersFormValidationSchema = Yup.object()
  .shape({
    agentCode: Yup.string(),
    recipientName: Yup.string(),
    documentName: Yup.string(),
    templateId: Yup.array().of(Yup.number()),
    documentTypeFromDict: Yup.array().of(Yup.string()),
    dateFrom: Yup.string(),
    dateTo: Yup.string(),
    isArchived: Yup.boolean(),
    documentStatus: Yup.array().of(
      Yup.mixed<DocumentStatus>().oneOf(Object.values(DocumentStatus))
    ),
    mySigningStatus: Yup.array().of(Yup.mixed<SigningStatus>().oneOf(Object.values(SigningStatus))),
    createdBy: Yup.array().of(Yup.string()),
  })
  .required(requiredFieldMessage);

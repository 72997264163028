import { Button, Icon, Text } from '@profitowi/component-library';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ActionButtonsProps {
  handleDelete: () => void;
  handleSend: () => void;
  handleArchive: () => void;
  generateReport: () => void;
  sendReminder: () => void;
  downloadDocuments: () => void;
  signDocuments: () => void;
  restoreDocument: () => void;
  isActiveDocumentsView: boolean;
  canDeleteDocument: boolean;
  canSendDocument: boolean;
  canArchiveDocument: boolean;
  canRemindDocument: boolean;
  canSignDocument: boolean;
  canDownloadDocuments: boolean;
  canRestoreDocuments: boolean;
}

function ActionButtons({
  handleDelete,
  handleSend,
  handleArchive,
  generateReport,
  sendReminder,
  downloadDocuments,
  signDocuments,
  restoreDocument,
  isActiveDocumentsView,
  canDeleteDocument,
  canSendDocument,
  canArchiveDocument,
  canRemindDocument,
  canSignDocument,
  canDownloadDocuments,
  canRestoreDocuments,
}: ActionButtonsProps) {
  const navigate = useNavigate();

  function startTemplateDocumentCreation() {
    navigate('create-document-from-template');
  }

  function startPdfDocumentCreation() {
    navigate('create-document-from-pdf');
  }

  if (!isActiveDocumentsView) {
    return (
      <div className="flex flex-col gap-2 w-fit">
        <Button
          isDisabled={!canDeleteDocument}
          className="w-56"
          size="sm"
          variant="outline-primary"
          onPress={() => handleDelete()}>
          <Text weight="semibold" className="flex gap-2 items-center w-full">
            <Icon name="trash-fill" />
            Usuń
          </Text>
        </Button>
        <Button
          className="w-56"
          size="sm"
          variant="outline-primary"
          onPress={() => generateReport()}>
          <Text weight="semibold" className="flex gap-2 items-center w-full">
            <Icon name="file-earmark-text-fill" />
            Wygeneruj raport
          </Text>
        </Button>
        <Button
          className="w-56"
          isDisabled={!canRestoreDocuments}
          size="sm"
          variant="outline-primary"
          onPress={() => restoreDocument()}>
          <Text weight="semibold" className="flex gap-2 items-center w-full">
            <Icon custom={false} name="arrow-counterclockwise" />
            Przywróć
          </Text>
        </Button>
        <Button
          className="w-56"
          isDisabled={!canDownloadDocuments}
          size="sm"
          variant="outline-primary"
          onPress={() => downloadDocuments()}>
          <Text weight="semibold" className="flex gap-2 items-center w-full">
            <Icon name="cloud-download-fill" />
            Pobierz dokumenty
          </Text>
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 w-fit">
      <Button
        isDisabled={!canDeleteDocument}
        className="w-56"
        size="sm"
        variant="outline-primary"
        onPress={() => handleDelete()}>
        <Text weight="semibold" className="flex gap-2 items-center w-full">
          <Icon name="trash-fill" />
          Usuń
        </Text>
      </Button>

      <Button
        isDisabled={!canSendDocument}
        className="w-56"
        size="sm"
        variant="outline-primary"
        onPress={() => handleSend()}>
        <Text weight="semibold" className="flex gap-2 items-center w-full">
          <Icon name="send-fill" />
          Wyślij
        </Text>
      </Button>

      <Button
        isDisabled={!canArchiveDocument}
        className="w-56"
        size="sm"
        variant="outline-primary"
        onPress={() => handleArchive()}>
        <Text weight="semibold" className="flex gap-2 items-center w-full">
          <Icon name="archive-fill" />
          Archiwizuj
        </Text>
      </Button>

      <Button className="w-56" size="sm" variant="outline-primary" onPress={() => generateReport()}>
        <Text weight="semibold" className="flex gap-2 items-center w-full">
          <Icon name="file-earmark-arrow-down-fill" />
          Wygeneruj raport
        </Text>
      </Button>

      <Button
        isDisabled={!canRemindDocument}
        className="w-56"
        size="sm"
        variant="outline-primary"
        onPress={() => sendReminder()}>
        <Text weight="semibold" className="flex gap-2 items-center w-full">
          <Icon name="bell-fill" />
          Wyślij przypomnienie
        </Text>
      </Button>

      <Button
        className="w-56"
        size="sm"
        isDisabled={!canDownloadDocuments}
        variant="outline-primary"
        onPress={() => downloadDocuments()}>
        <Text weight="semibold" className="flex gap-2 items-center w-full">
          <Icon name="download" />
          Pobierz dokumenty
        </Text>
      </Button>

      <Button
        isDisabled={!canSignDocument}
        className="w-56"
        size="sm"
        variant="outline-primary"
        onPress={() => signDocuments()}>
        <Text weight="semibold" className="flex gap-2 items-center w-full">
          <Icon name="pencil-fill" />
          Podpisz dokumenty
        </Text>
      </Button>

      <Button
        className="w-56"
        size="sm"
        variant="outline-primary"
        onPress={() => startPdfDocumentCreation()}>
        <Text weight="semibold" className="flex gap-2 items-center w-full">
          <Icon name="plus-lg" />
          Dokument PDF
        </Text>
      </Button>

      <Button
        className="w-56"
        size="sm"
        variant="outline-primary"
        onPress={() => startTemplateDocumentCreation()}>
        <Text weight="semibold" className="flex gap-2 items-center w-full">
          <Icon name="plus-lg" />
          Dokument z szablonu
        </Text>
      </Button>
    </div>
  );
}

export default ActionButtons;

import { Header, Option } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';

import { DICTIONARY_TYPES } from 'constants/dictionaryTypes';
import useDictionaryContext from 'hooks/useDictionaryContext';

import {
  duplicateFileMessage,
  emptyFileInputMessage,
  requiredFieldMessage,
} from '../../../../../../constants/requiredFieldMessage';
import { TemplateCreatorDataContent } from '../../../../../../types/templateCreator';
import ErrorModal from './Modals/ErrorModal';
import TemplateForm from './TemplateForm/TemplateForm';
import VariablesList from './VariablesList/VariablesList';

const validationSchemaEditTemplate = Yup.object({
  name: Yup.string(),
  description: Yup.string(),
  content: Yup.string().required(requiredFieldMessage),
  documentType: Yup.mixed(),
  attachments: Yup.array().of(
    Yup.object({
      file: Yup.mixed(),
      uploadedFileId: Yup.number(),
    })
      .nullable()
      .required(emptyFileInputMessage)
      .test('unique-file-name', duplicateFileMessage, function (value, context) {
        const formValues = (context.options as any).from?.[(context.options as any).from.length - 1]
          ?.value;

        if (!formValues) return true;

        const { attachments } = formValues;

        const duplicateInAttachments = attachments.filter(
          (attachment: any) => attachment.file?.name === value?.file?.name
        );

        return duplicateInAttachments.length <= 1;
      })
  ),
});

const validationSchemaNewTemplate = Yup.object({
  name: Yup.string().required(requiredFieldMessage),
  description: Yup.string(),
  content: Yup.string().required(requiredFieldMessage),
  documentType: Yup.mixed(),
  attachments: Yup.array().of(
    Yup.object({
      file: Yup.mixed(),
      uploadedFileId: Yup.number(),
    })
      .nullable()
      .required(emptyFileInputMessage)
      .test('unique-file-name', duplicateFileMessage, function (value, context) {
        const formValues = (context.options as any).from?.[(context.options as any).from.length - 1]
          ?.value;

        if (!formValues) return true;

        const { attachments } = formValues;

        const duplicateInAttachments = attachments.filter(
          (attachment: any) => attachment.file?.name === value?.file?.name
        );

        return duplicateInAttachments.length <= 1;
      })
  ),
});

export type EditTemplateFormValues = Yup.InferType<typeof validationSchemaEditTemplate>;
export type NewTemplateFormValues = Yup.InferType<typeof validationSchemaNewTemplate>;
export type TemplateFormValues = EditTemplateFormValues | NewTemplateFormValues;

interface Params {
  handleSubmit: (values: TemplateFormValues) => void;
  handleCancel: () => void;
  isError?: boolean;
  error?: AxiosError;
  editedItem?: TemplateCreatorDataContent;
  allowContentEditOnly?: boolean;
  resetErrors?: () => void;
  isLoading?: boolean;
  displayContentOnly?: boolean;
}

function EditTemplateForm({
  editedItem,
  handleSubmit,
  handleCancel,
  isError,
  error,
  resetErrors,
  allowContentEditOnly,
  isLoading,
  displayContentOnly,
}: Params) {
  const { activeDictionaries } = useDictionaryContext();

  const documentTypeOptions: Option[] = useMemo(() => {
    return activeDictionaries?.[DICTIONARY_TYPES.DOCUMENT_TYPE] || [];
  }, [activeDictionaries]);

  const initialValues: EditTemplateFormValues | NewTemplateFormValues = useMemo(
    () => ({
      name: editedItem?.name || '',
      documentType: editedItem?.documentType || documentTypeOptions[0]?.value,
      description: editedItem?.description || '',
      content: editedItem?.content || '',
      attachments:
        editedItem?.attachments?.map((attachment) => ({
          file: new File([], attachment.fileName),
          uploadedFileId: attachment.id,
        })) || [],
    }),
    [editedItem, documentTypeOptions]
  );

  return (
    <div className="w-full">
      <Header as="h4" size="lg" weight="semibold" className="mb-4">
        Treść szablonu
      </Header>

      <div className="template-form-width">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={
            !!editedItem ? validationSchemaEditTemplate : validationSchemaNewTemplate
          }>
          <TemplateForm
            documentTypeOptions={documentTypeOptions}
            isLoading={isLoading}
            displayContentOnly={displayContentOnly}
            allowContentEditOnly={allowContentEditOnly}
            handleCancel={handleCancel}
          />
        </Formik>
        <div className="w-80 flex items-end">
          <VariablesList />
        </div>
      </div>

      {isError && (
        <ErrorModal
          isError={isError}
          cancelButtonLabel={resetErrors && 'Anuluj proces'}
          confirmButtonLabel="Powróć do edycji"
          error={error}
          onCancel={handleCancel}
          onConfirm={resetErrors}
        />
      )}
    </div>
  );
}

export default EditTemplateForm;

import { Alert, InputField, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import useAgentId from 'pages/Agent/useAgentId';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ModalActions } from 'components';
import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { phoneNumberFormatError } from 'constants/requiredFieldMessage';
import { modifyAgentsContactInfo } from 'services/agent';
import { EconomyActivities, ModifyAgentsContactPayload } from 'types/agent';

const validationSchema = yup.object({
  businessEmail: yup.string().defined('Nieprawidłowy email').default(''),
  privateEmail: yup.string().defined().typeError('Nieprawidłowy email').default(''),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]{9,11}$/, phoneNumberFormatError)
    .defined()
    .typeError(phoneNumberFormatError)
    .default(''),
});

type Props = {
  economyActivities?: EconomyActivities;

  refetchAgent: () => void;
};

type ModifyAgentsContactParams = {
  payload: ModifyAgentsContactPayload;
  cooperationId: number;
};

const ContactForm = ({ economyActivities, refetchAgent }: Props) => {
  const agentId = useAgentId();
  const navigate = useNavigate();

  const initialValues = useMemo(() => {
    if (economyActivities) {
      const { businessEmail, privateEmail, phoneNumber } = economyActivities;

      return {
        businessEmail,
        privateEmail,
        phoneNumber,
      };
    }
  }, [economyActivities]);

  const {
    isLoading: isUpdateLoading,
    error: updateError,
    mutate,
  } = useMutation<AxiosResponse, AxiosError, ModifyAgentsContactParams>(
    ({ payload, cooperationId }) => modifyAgentsContactInfo(agentId, cooperationId, payload),
    {
      onSuccess: () => {
        refetchAgent();
        navigate('..');
      },
    }
  );

  return (
    <Modal title={'Edytuj dane kontaktowe Kontrahenta'} visible>
      {economyActivities ? (
        <Formik
          initialValues={initialValues ?? validationSchema.cast({})}
          onSubmit={(values) => mutate({ payload: values, cooperationId: economyActivities.id })}
          validationSchema={validationSchema}>
          <Form className="space-y-4">
            <InputField name="businessEmail" label="E-mail służbowy" />
            <InputField name="privateEmail" label="E-mail prywatny" />
            <InputField name="phoneNumber" label="Numer telefonu" />

            <ModalActions isLoading={isUpdateLoading} onCancel={() => navigate('..')} />
            {updateError && <ErrorMessages error={updateError} />}
          </Form>
        </Formik>
      ) : (
        <Alert type="error">'Brak danych kontrahenta"</Alert>
      )}
    </Modal>
  );
};

export default ContactForm;

import React, { useState } from 'react';

import EditTemplate from './EditTemplate/EditTemplate';
import TemplateCreatorList from './TemplateCreatorList/TemplateCreatorList';
import { TemplateCreatorActions } from './TemplateCreatorList/columns';

export enum TemplateCreatorTabs {
  LIST_VIEW = 'LIST_VIEW',
  FORM_VIEW = 'FORM_VIEW',
}

function TemplateCreator() {
  const [currentTab, setCurrentTab] = useState<TemplateCreatorTabs>(TemplateCreatorTabs.LIST_VIEW);
  const [templateFormAction, setTemplateFormAction] = useState<TemplateCreatorActions>();
  const [editId, setEditId] = useState<number>();

  if (currentTab === TemplateCreatorTabs.FORM_VIEW && templateFormAction) {
    return (
      <>
        <EditTemplate
          editId={editId}
          templateFormAction={templateFormAction}
          setTemplateFormAction={setTemplateFormAction}
          setEditId={setEditId}
          setCurrentTab={setCurrentTab}
        />
      </>
    );
  } else {
    return (
      <>
        <TemplateCreatorList
          setCurrentTab={setCurrentTab}
          setTemplateFormAction={setTemplateFormAction}
          setEditId={setEditId}
        />
      </>
    );
  }
}

export default TemplateCreator;

import {
  AutoCompleteField,
  Button,
  DatePickerField,
  Header,
  Icon,
  InputField,
  Option,
  SelectField,
  Text,
} from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { FieldArray, Form, useFormikContext } from 'formik';
import { CreateTemplateDocumentFormValuesStep1 } from 'pages/Documents/CreateDocumentFromTemplate/CreateTemplateDocumentFormData/createTemplateDocumentFormValidationSchema';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';

import InsetLoader from 'components/InsetLoader/InsetLoader';
import { USER } from 'constants/queries/user';
import { getCreatorTemplate, getTemplateCreatorData } from 'services/templateCreator';
import { getUsersByRole } from 'services/user';
import { TemplateCreatorDataContent } from 'types/templateCreator';
import { Roles, User } from 'types/user';

import { DICTIONARY_TYPES } from '../../../../../../constants/dictionaryTypes';
import useDictionaryContext from '../../../../../../hooks/useDictionaryContext';
import { DocumentPaperType, ProxyRoles } from '../../../../../../types/documents';

interface Props {
  onReset: () => void;
}

const InitialDataModal = ({ onReset }: Props) => {
  const [queryUser, setQueryUser] = useState<string>('');
  const [initialUserList, setInitialUserList] = useState<User[]>([]);
  const { activeDictionaries } = useDictionaryContext();
  const { values, errors, touched, setFieldValue } =
    useFormikContext<CreateTemplateDocumentFormValuesStep1>();
  const [debouncedQueryParam] = useDebounce(queryUser, 500);

  const paperTypeOptions = Object.entries(DocumentPaperType).map((value) => ({
    key: value[0],
    value: value[1],
  }));

  const proxyRolesOptions = Object.entries(ProxyRoles).map((value) => ({
    key: value[0],
    value: value[1],
  }));

  const {
    data: availableTemplatesData,
    isLoading: availableTemplatesIsLoading,
    isFetching: availableTemplatesIsFetching,
  } = useQuery(
    ['availableTemplatesCreator', values.documentType],
    () => getTemplateCreatorData({ archived: false, documentType: values.documentType }, 0, 9999),
    {
      select: (data): Option[] =>
        data.content.map((template) => ({
          key: template.id,
          value: template.name,
        })),
    }
  );

  const {
    isError: templateIsError,
    error: templateError,
    isLoading: templateIsLoading,
    isFetching: templateIsFetching,
  } = useQuery<TemplateCreatorDataContent, AxiosError>(
    ['getCreatorTemplate', values.template],
    () => getCreatorTemplate(values.template),
    {
      enabled: Number.isInteger(values.template),
      onSuccess: (data) => {
        setFieldValue('selectedTemplate', data);
        setFieldValue('customContent', data.content);
        setFieldValue(
          'attachmentsFromTemplates',
          data.attachments.map((attachment) => attachment.s3Key)
        );
      },
    }
  );

  const { data: userListData, isLoading: isLoadingUserList } = useQuery<User[], AxiosError>(
    [USER.USERS_BY_ROLE],
    () => getUsersByRole(Roles.DOCUMENTS_MANAGEMENT_SEND)
  );

  useEffect(() => {
    if (!initialUserList.length) {
      setInitialUserList(userListData || []);
    }
  }, [userListData]);

  const userOptions = useCallback(
    (index: number): Option[] => {
      if (!userListData) return [];
      const selectedProxies = values.proxies?.filter((proxy) => !!proxy);

      const availableOptions = userListData
        .filter(
          (user) =>
            user.firstName?.includes(debouncedQueryParam) ||
            user.lastName?.includes(debouncedQueryParam) ||
            user.agentCode?.includes(debouncedQueryParam) ||
            user.email?.includes(debouncedQueryParam)
        )
        .map((user) => ({
          key: user.keycloakId,
          value: user.firstName + ' ' + user.lastName,
        }));

      if (!selectedProxies) return availableOptions;

      return availableOptions.filter(
        (item) =>
          !selectedProxies.find((proxy) => proxy.id === item.key) ||
          selectedProxies[index]?.id === item.key
      );
    },
    [userListData, values.proxies, debouncedQueryParam]
  );

  function onSubmit() {
    const proxies = values.proxies?.map((proxy) => {
      const userData = initialUserList.find((option) => option.keycloakId === proxy.id);
      return {
        ...proxy,
        name: userData?.firstName + ' ' + userData?.lastName,
      };
    });

    setFieldValue('proxies', proxies);
    setFieldValue('documentName', values.documentName.trim());
  }

  useEffect(() => {
    if (values.documentName?.length === 0) {
      setFieldValue('documentName', '');
    }
  }, [values.documentName]);

  return (
    <div className="relative">
      {isLoadingUserList && <InsetLoader />}

      <div className="flex flex-col items-center">
        <Header as="h1" size="xl" weight="semibold">
          Utwórz dokument z szablonu
        </Header>
        <Text size="lg">Krok 1 - dane podstawowe</Text>
      </div>

      <div>
        <Form>
          <div className="my-4 flex flex-col gap-2">
            <InputField label="Nazwa dokumentu" name="documentName" />
            <Text size="sm" className="text-slate-500 text-center">
              Przy wysyłce na końcu nazwy każdego dokumentu zostanie dodany numer PR odbiorcy.
            </Text>
            <SelectField
              name="documentType"
              label="Rodzaj dokumentu"
              options={
                activeDictionaries ? activeDictionaries?.[DICTIONARY_TYPES.DOCUMENT_TYPE] : []
              }
            />
            <SelectField name="template" label="Szablon" options={availableTemplatesData || []} />
            <SelectField name="paperType" label="Rodzaj papieru" options={paperTypeOptions} />
            <DatePickerField dateFormat={'dd-MM-yyyy'} label="Data dokumentu" name="documentDate" />

            <FieldArray name="proxies">
              {(helpers) => (
                <div>
                  <div className="my-8 flex justify-between">
                    <Text weight="bold" size="lg">
                      Pełnomocnicy
                    </Text>
                    <Button className="flex gap-2" size="sm" onPress={() => helpers.push({})}>
                      <Icon name="plus-lg" />
                      <Text>Dodaj kolejnego pełnomocnika</Text>
                    </Button>
                  </div>
                  <div>
                    {values.proxies?.map((proxy: any, index: any) => (
                      <div className="flex flex-col gap-4 my-8" key={index}>
                        <AutoCompleteField
                          label="Pełnomocnik"
                          name={`proxies[${index}].id`}
                          options={userOptions(index)}
                          placeholder="Pełnomocnik"
                          query={queryUser}
                          setQuery={setQueryUser}
                          className={`${
                            touched.proxies && (errors?.proxies?.[index] as any)?.id
                              ? 'border border-red-700'
                              : ''
                          }`}
                        />
                        <div className="flex w-full gap-2">
                          <div className="w-full">
                            <SelectField
                              options={proxyRolesOptions}
                              name={`proxies[${index}].role`}
                              label="Rola"
                              className={`${
                                touched.proxies && (errors?.proxies?.[index] as any)?.role
                                  ? 'border border-red-700'
                                  : ''
                              }`}
                            />
                          </div>
                          <Button
                            onPress={() => helpers.remove(index)}
                            className="flex gap-2 self-end">
                            <i className="bi bi-trash cursor-pointer" />
                          </Button>
                        </div>
                        {touched.proxies && errors?.proxies?.[index] && (
                          <p className="text-xs font-normal text-red-700">
                            {(errors?.proxies?.[index] as any).name}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </FieldArray>
          </div>

          {templateIsError && (
            <div>
              <p className="text-xs font-normal text-red-700">{templateError}</p>
            </div>
          )}

          <div className="flex justify-end gap-4">
            <Button
              className="flex space-x-2"
              onPress={onReset}
              type="reset"
              variant="outline-primary">
              <Icon name="x-lg" />
              <Text>Anuluj</Text>
            </Button>
            <Button
              className="flex space-x-2"
              type="submit"
              variant="primary"
              onPress={onSubmit}
              isDisabled={
                !!Object.entries(errors).length ||
                templateIsLoading ||
                templateIsFetching ||
                availableTemplatesIsLoading ||
                availableTemplatesIsFetching
              }>
              <Icon name="arrow-right" />
              <Text>Dalej</Text>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default InitialDataModal;

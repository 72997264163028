import { Box, Loader, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { DOCUMENTS_LIST } from 'constants/queries/documentsList';
import { getDocument, updateDocument } from 'services/documents';
import { GetDocumentTemplateResponse } from 'types/documents';
import { TemplateCreatorDataContent } from 'types/templateCreator';

import EditTemplateForm, {
  TemplateFormValues,
} from '../../../../Settings/components/TemplateCreator/EditTemplate/EditTemplateForm/EditTemplateForm';

interface Props {
  refetch: () => Promise<any>;
}

const EditDocumentModal = ({ refetch }: Props) => {
  const navigate = useNavigate();
  const [payload, setPayload] = useState<TemplateCreatorDataContent>();
  const { state } = useLocation();
  const editedDocumentId: number = state?.documentId;

  useEffect(() => {
    if (!editedDocumentId) {
      navigate('..');
    }
  }, []);

  const { isError, isLoading, isFetching, isSuccess, error } = useQuery<
    GetDocumentTemplateResponse,
    AxiosError
  >([DOCUMENTS_LIST.SINGLE_DOCUMENT, editedDocumentId], () => getDocument(editedDocumentId), {
    onSuccess: (values) => {
      setPayload({
        content: values.customContent,
      } as TemplateCreatorDataContent);
    },
    enabled: !!editedDocumentId,
  });

  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    reset,
  } = useMutation<AxiosResponse, AxiosError, { content: string }>(
    ({ content }: { content: string }) =>
      updateDocument({ id: editedDocumentId, content: content }),
    {
      onSuccess: () => {
        refetch();
        setPayload(undefined);
        navigate('..');
      },
    }
  );

  const handleSubmit = (formValues: TemplateFormValues) => {
    mutateUpdate({ content: formValues.content });
  };

  function onCancel() {
    refetch().then(() => navigate('..'));
  }

  return (
    <Modal className="!max-w-[90%] h-full" visible>
      {(isLoading || isFetching) && <Loader className="h-12 w-12" />}

      {isError && (
        <div className="my-4">
          <ErrorMessages error={error} />
        </div>
      )}

      {isSuccess && !!payload && (
        <div className="w-full h-full overflow-hidden relative">
          <Box className="flex flex-col">
            <EditTemplateForm
              editedItem={payload}
              displayContentOnly
              isError={isErrorUpdate}
              error={errorUpdate as AxiosError}
              isLoading={isLoadingUpdate}
              handleSubmit={handleSubmit}
              handleCancel={onCancel}
              resetErrors={reset}
            />
          </Box>
        </div>
      )}
    </Modal>
  );
};

export default EditDocumentModal;
